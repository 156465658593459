import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";

import {
  FETCH_CARD_LIST,
  FETCH_CARD_LIST_SUCCESS,
  FETCH_CARD_LIST_ERROR,
} from "../actions/cards";

import CardList from "../types/cardList";

const initialState = Immutable.from({
  isFetching: false,
  data: null,
  error: null,
});

export default handleActions({
  [FETCH_CARD_LIST]: state => state.merge({
    isFetching: true,
    error: null,
    data: null,
  }),
  [FETCH_CARD_LIST_ERROR]: (state, { payload }) => state.merge({
    isFetching: false,
    error: payload,
    data: null,
  }),
  [FETCH_CARD_LIST_SUCCESS]: (state, { payload }) => state.merge({
    isFetching: false,
    error: null,
    data: new CardList(payload),
  }),
}, initialState);
