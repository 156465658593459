import { connect } from "react-redux";

import { getData, getError } from "../selectors/people";
import { addPerson, fetchPeople } from "../actions/people";

import People from "../components/People";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  data: getData(state),
  error: getError(state),
});

const mapDispatchToProps = dispatch => ({
  addNewPerson: () => {
    dispatch(addPerson());
  },
  fetch: () => {
    dispatch(fetchPeople());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(People);

