import PropTypes from "prop-types";
import Immutable from "seamless-immutable";

import CardSide from "./cardSide";

class Card {
  constructor({
    id,
    name,
    front,
    back,
  }) {
    return Immutable.from({
      id,
      name,
      front: new CardSide(front),
      back: new CardSide(back),
    }, { prototype: this.constructor.prototype });
  }
}

const cardPropType = PropTypes.instanceOf(Card);

export {
  Card as default,
  cardPropType,
};
