import PropTypes from "prop-types";
import Immutable from "seamless-immutable";

import CardComponent from "./cardComponent";

class CardSide {
  constructor({
    htmlOnly,
    border,
    color,
    backgroundColor,
    linearGradient,
    style,
    children,
  }) {
    return Immutable.from({
      htmlOnly,
      border,
      color,
      backgroundColor,
      linearGradient,
      style : style || {},
      children: (children || []).map(child => new CardComponent(child)),
    }, { prototype: this.constructor.prototype });
  }
}

export const cardSidePropType = PropTypes.instanceOf(CardSide);

export default CardSide;
