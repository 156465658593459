import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, FormControl, ControlLabel, HelpBlock } from "react-bootstrap";
import { Redirect } from "react-router-dom";

class LoginPage extends Component {
  static displayName = "LoginPage";

  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    sendCredentials: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      userName: "",
      password: "",
      errorMsg: "",
	};
  }

  handleUserChange(e) {
    e.preventDefault();

	this.setState({
      userName: e.target.value,
    });
  }

  handlePasswordChange(e) {
    e.preventDefault();

	this.setState({
      password: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { userName, password } = this.state;
	let errorMsg = "";

    if (userName.length === 0) {
      errorMsg = "Please enter user name";
    } else if (password.length === 0) {
      errorMsg = "Please enter password";
    }

	if (errorMsg.length === 0) {
      // TODO: Send user and password info
      this.props.sendCredentials(userName, password);
    }

	this.setState({
      errorMsg,
    });
  }

  render() {
    const { userName, password, errorMsg } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (this.props.isLoggedIn) {
      return <Redirect to={from} />;
    }

    return(
      <form onSubmit={this.handleSubmit.bind(this)}>
        <FormGroup controlId="userName">
          <ControlLabel>User Name</ControlLabel>
          <FormControl
            type="text"
            value={userName}
            placeholder="Enter Username"
            onChange={this.handleUserChange.bind(this)}
		  />
        </FormGroup>
        <FormGroup controlId="password">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={password}
            placeholder="Enter password"
            onChange={this.handlePasswordChange.bind(this)}
		  />
        </FormGroup>
        {(errorMsg.length > 0) ? <HelpBlock>{errorMsg}</HelpBlock> : null}
        <Button type="submit">Login</Button>
      </form>
    );
  }
}

export default LoginPage;
