import { createAction } from "redux-actions";

export const SET_PALLETTE_SELECTION = "EDITOR_SETTINGS/SET_PALLETTE_SELECTION";
export const SET_SELECTED_ITEM = "EDITOR_SETTINGS/SET_SELECTED_ITEM";
export const SET_SIDE = "EDITOR_SETTINGS/SET_SIDE";
export const RESET_SETTINGS = "EDITOR_SETTINGS/RESET_SETTINGS";

export const setPalletteSelection = createAction(SET_PALLETTE_SELECTION);
export const setSelectedItem = createAction(SET_SELECTED_ITEM);
export const setSide = createAction(SET_SIDE);
export const resetSettings = createAction(RESET_SETTINGS);
