import { createAction } from "redux-actions";
import { TEMPLATES } from "../helpers/paths";
import { getAccountId } from "../selectors/userAccount";
import { getNextId } from "../selectors/cards";
import { initNewCard } from "../actions/cardEditor";

const FETCH_CARD_LIST = "CARDS/FETCH_CARD_LIST";
const FETCH_CARD_LIST_SUCCESS = "CARDS/FETCH_CARD_LIST_SUCCESS";
const FETCH_CARD_LIST_ERROR = "CARDS/FETCH_CARD_LIST_ERROR";

export const ADD_NEW_CARD = "CARDS/ADD_NEW_CARD";
export const DELETE_CARD = "CARDS/DELETE_CARD";

const fetchCardListSuccess = createAction(FETCH_CARD_LIST_SUCCESS);
const fetchCardListError = createAction(FETCH_CARD_LIST_ERROR);
const fetchCardList = createAction(
  FETCH_CARD_LIST,
  null,
  () => ({
    method: "GET",
    view: "cardList",
    path: "template",
    params: {
      accountId: getAccountId,
    },
    needsAuthorization: true,
    onSuccess: fetchCardListSuccess,
    onError: fetchCardListError,
  }),
);

export const addNewCard = createAction(
  ADD_NEW_CARD,
  null,
  () => ({
    nextId: getNextId,
    initNew: initNewCard,
    path: TEMPLATES,
  }),
);

export const deleteCard = createAction(DELETE_CARD);

export {
  FETCH_CARD_LIST,
  FETCH_CARD_LIST_SUCCESS,
  FETCH_CARD_LIST_ERROR,
  fetchCardList,
  fetchCardListSuccess,
  fetchCardListError,
};

