import PropTypes from "prop-types";
import Immutable from "seamless-immutable";

class Address {
  constructor({
    name,
    street,
    suburb,
    state,
    postcode,
    country,
  }) {
    return Immutable.from({
      name,
      street,
      suburb,
      state,
      postcode,
      country,
    }, { prototype: this.constructor.prototype });
  }
}

class UserInfo {
  constructor({
    name: {
      first,
      middle,
      surname,
    },
    company: {
      name,
      url,
    },
    address,
    postalAddress,
    phone: {
      mobile,
      work,
      fax,
    },
    email,
    logo,
    jobTitle,
  }) {
    return Immutable.from({
      name: {
        first,
        middle,
        surname,
      },
      company: {
        name,
        url,
      },
      address: new Address(address || {}),
      postalAddress: new Address(postalAddress || {}),
      phone: {
        mobile,
        work,
        fax,
      },
      email,
      logo,
      jobTitle,
    }, { prototype: this.constructor.prototype });
  }
}

const userInfoPropType = PropTypes.instanceOf(UserInfo);

export {
  UserInfo as default,
  userInfoPropType,
};
