import { createSelector } from "reselect";

const getUserCardState = state => state.userCard;

export const getIsFetching = createSelector(
  getUserCardState,
  userCard => Boolean(userCard.isFetching),
);

export const getUserCard = createSelector(
  getUserCardState,
  userCard => userCard.data,
);

export const getError = createSelector(
  getUserCardState,
  userCard => userCard.error,
);
