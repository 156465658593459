import { connect } from "react-redux";

import { getData, getError } from "../selectors/cards";
import { addNewCard, fetchCardList } from "../actions/cards";

import Cards from "../components/Cards";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  data: getData(state),
  error: getError(state),
});

const mapDispatchToProps = dispatch => ({
  addNewCard: () => {
    dispatch(addNewCard());
  },
  fetch: () => {
    dispatch(fetchCardList());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cards);

