import React, { Component } from "react";
import PropTypes from "prop-types";
import { Text } from "react-native";
import { pick } from "lodash";

import PhoneNumber from "../../../components/PhoneNumber";

import { textPropTypes, defaultTextProps } from "./propTypes";
import { formatTextStyle, isReferencePhone, getFieldText } from "./utils";

const VALID_TEXT_PROPS = ["onClick"];

class Field extends Component {
  static propTypes = {
    ...textPropTypes,
    reference: PropTypes.string.isRequired,
    userInfo: PropTypes.object.isRequired,
  };

  static defaultProps = {
    ...defaultTextProps,
  };

  renderPhoneNumber(fieldText) {
    const { style, baseTextStyle } = this.props;

    return (
      <PhoneNumber
        phone={fieldText}
        textStyle={formatTextStyle(style, baseTextStyle)}
      />
    );
  }

  renderTextField(fieldText) {
    const { style, baseTextStyle, ...otherProps } = this.props;

    return (
      <Text
        style={formatTextStyle(style, baseTextStyle)}
        {...pick(otherProps, VALID_TEXT_PROPS)}
      >
        {fieldText || ""}
      </Text>
    );
  }

  render() {
    const { reference, userInfo } = this.props;
    let content = null;
    const fieldText = getFieldText(userInfo, reference);

    if (fieldText) {
      content = isReferencePhone(reference)
        ? this.renderPhoneNumber(fieldText)
        : this.renderTextField(fieldText);
    }

    return content;
  }
}

export default Field;
