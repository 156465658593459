import React from "react";
import { Text } from "react-native";
import { pick } from "lodash";

import { textPropTypes, contentPropType, defaultTextProps } from "./propTypes";
import { formatTextStyle } from "./utils";

const VALID_TEXT_PROPS = ["onClick"];

const TextComponent = ({ style, content, baseTextStyle, ...otherProps }) => (
  <Text
    style={formatTextStyle(style, baseTextStyle)}
    {...pick(otherProps, VALID_TEXT_PROPS)}
  >
    {content}
  </Text>
);
TextComponent.propTypes = {
  ...textPropTypes,
  content: contentPropType,
};
TextComponent.defaultProps = {
  ...defaultTextProps,
};

export default TextComponent;
