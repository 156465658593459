import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useNewComponentDrop } from "./dragDropConstants";
import WireFrameComponent from "./WireFrameComponent";
import Container from "../../app/Vbiz/vbiz/shared/components/JsonCard/Container";

import { cardSidePropType } from "../../types/cardSide";

import "../../css/WireFrame.css";

const DUMMY_USER = {
  name: {
    first: "John",
    middle: "Middle",
    surname: "Citizen",
    title: "Sir",
    suffix: "Sr",
  },
  company: {
    name: "My Company",
    url: "http://www.vbizapp.com",
  },
  address: {
    name: "address name",
    street: "123 Fake Street",
    suburb: "Mytown",
    postcode: "90210",
    state: "State",
    country: "Australia",
  },
  postalAddress: {
    name: "Postal name",
    street: "321 Fake Street",
    suburb: "Postown",
    postcode: "90212",
    state: "State",
    country: "Australia",
  },
  phone: {
    work: "+61 2 9555-5555",
    mobile: "0410-555-555",
    fax: "+61 2 9666-6666",
  },
  email: "me@vbizapp.com",
  jobTitle: "Job Title",
};

const CONTAINER_STYLE = {
  height: '24rem',
  width: '36rem',
  borderWidth: '1px',
  borderColor: 'black',
  borderStyle: 'solid',
};

export const WireFrameHolder = ({
  className,
  selectedItem,
  cardSide: {
    style,
    children,
    ...otherProps
  },
  setSelectedItem,
}) => {
  const [{ isOver }, drop] = useNewComponentDrop(0);

  return (
    <div
      className={classNames(className, "wire_frame_container")}
      ref={drop}
    >
      <Container
        style={{
          ...CONTAINER_STYLE,
          ...style,
          backgroundColor: isOver ? 'orange' : style.backgroundColor,
        }}
        onClick={() => setSelectedItem(0)}
        {...otherProps}
      >
        {
          children.map(
            child =>
              <WireFrameComponent
                content={child}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                key={Math.random()}
                userInfo={DUMMY_USER}
              />
          )
        }
      </Container>
    </div>
  )
};

WireFrameHolder.displayName = "WireFrameHolder";
WireFrameHolder.propTypes = {
  className: PropTypes.string,
  selectedItem: PropTypes.number,
  cardSide: cardSidePropType.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};
