import { connect } from "react-redux";

import {
  getData,
  getError,
  getIsEditable,
  getIsNew,
  getIsModified
} from "../selectors/peopleEditor";
import { fetchPerson, setUserInfoField, savePerson } from "../actions/peopleEditor";

import PeopleEditor from "../components/PeopleEditor";

const mapStateToProps = (state, ownProps) =>({
  ...ownProps,
  data: getData(state),
  error: getError(state),
  isNew: getIsNew(state),
  isEditable: getIsEditable(state),
  isModified: getIsModified(state),
});

const mapDispatchToProps = dispatch => ({
  fetch: id => {
    dispatch(fetchPerson(id));
  },
  setUserInfoField: (path, value) => {
    dispatch(setUserInfoField({ path, value }));
  },
  savePerson: (data, isNew) => {
    dispatch(savePerson({
      person: data,
      isNew,
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  savePerson: () => {
    dispatchProps.savePerson(stateProps.data, stateProps.isNew);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PeopleEditor);

