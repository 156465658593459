import React, { useEffect, Component } from "react";
import PropTypes from "prop-types";
import { Form, FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { PEOPLE } from "../helpers/paths";
import { personPropType } from "../types/person";
import { errorPropType } from "../types/error";

import "../css/PeopleEditor.css";

class PeopleEditorContent extends Component {
  handleSaveClick = (e) => {
    e.preventDefault();
    this.props.savePerson();
  };

  handleFieldChange = (fieldName) => (e) => {
    this.props.setUserInfoField(fieldName, e.target.value);
  };

  render() {
    const {
      person: { userInfo },
      isModified,
    } = this.props;

    return (
      <Form>
        <FormGroup className="name_fields">
          <ControlLabel>Name</ControlLabel>
          <FormControl
            value={userInfo.name.first || ""}
            onChange={this.handleFieldChange("name.first")}
          />
          <FormControl
            value={userInfo.name.middle || ""}
            onChange={this.handleFieldChange("name.middle")}
          />
          <FormControl
            value={userInfo.name.surname || ""}
            onChange={this.handleFieldChange("name.surname")}
          />
        </FormGroup>
        <FormGroup className="people_editor_inline_group">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            value={userInfo.email || ""}
            onChange={this.handleFieldChange("email")}
          />
        </FormGroup>
        <FormGroup className="people_editor_inline_group">
          <ControlLabel>Mobile</ControlLabel>
          <FormControl
            value={userInfo.phone.mobile || ""}
            onChange={this.handleFieldChange("phone.mobile")}
          />
        </FormGroup>
        <FormGroup className="people_editor_inline_group">
          <ControlLabel>Work Phone</ControlLabel>
          <FormControl
            value={userInfo.phone.work || ""}
            onChange={this.handleFieldChange("phone.work")}
          />
        </FormGroup>
        <FormGroup className="people_editor_inline_group">
          <ControlLabel>Fax</ControlLabel>
          <FormControl
            value={userInfo.phone.fax || ""}
            onChange={this.handleFieldChange("phone.fax")}
          />
        </FormGroup>
        <p>Company Name: <span>{userInfo.company.name}</span></p>
        <p>Company URL: <span>{userInfo.company.url}</span></p>
        <Button disabled={!isModified} onClick={this.handleSaveClick}>Save</Button>
      </Form>
    );
  }
}

PeopleEditorContent.displayName = "PeopleEditorContent";
PeopleEditorContent.propTypes = {
  person: personPropType.isRequired,
  isEditable: PropTypes.bool.isRequired,
  setUserInfoField: PropTypes.func.isRequired,
  isModified: PropTypes.bool.isRequired,
  savePerson: PropTypes.func.isRequired,
};

const PeopleEditor = ({
  match: {
    params: {
      id: personId,
    },
  },
  fetch,
  isNew,
  data,
  error,
  ...otherProps
}) => {
  useEffect(() => {
    if (!isNew) {
      fetch(personId);
    }
  }, [fetch, isNew, personId]);

  let content;

  if (error) {
    content = <h3>Error</h3>;
  } else if (data) {
    content = (
      <PeopleEditorContent
        {...otherProps}
        person={data}
      />
    );
  } else {
    content = <h3>Fetching...</h3>;
  }

  return (
    <div>
      <h3>People Editor</h3>
      <p>{`Person ID: ${personId}`}</p>
      {content}
      <Link to={PEOPLE}>Back to People</Link>
    </div>
  );
};

PeopleEditor.displayName = "PeopleEditor";
PeopleEditor.propTypes = {
  fetch: PropTypes.func.isRequired,
  error: errorPropType,
  data: personPropType,
  isNew: PropTypes.bool.isRequired,
};

export default PeopleEditor;
