import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CARD_BUILDER } from "../helpers/paths";

import "../css/AppHome.css";

const AppHome = () => (
  <div className="app_home_container">
    <Helmet>
      <title>VBiz</title>
    </Helmet>
    <div>Info about VBiz</div>
    <Link to={CARD_BUILDER}>Card Builder</Link>
  </div>
);

export default AppHome;
