import { createAction } from "redux-actions";

import { getAccountId, getOrgId } from "../selectors/userAccount";
import { getData } from "../selectors/peopleEditor";

const FETCH_PERSON = "PEOPLE_EDITOR/FETCH_PERSON";
const FETCH_PERSON_SUCCESS = "PEOPLE_EDITOR/FETCH_PERSON_SUCCESS";
const FETCH_PERSON_ERROR = "PEOPLE_EDITOR/FETCH_PERSON_ERROR";

const INIT_NEW_PERSON = "PEOPLE_EDITOR/INIT_NEW_PERSON";

const SET_USER_INFO_FIELD = "PEOPLE_EDITOR/SET_USER_INFO_FIELD";

const SAVE_PERSON = "PEOPLE_EDITOR/SAVE_PERSON";
const SAVE_PERSON_SUCCESS = "PEOPLE_EDITOR/SAVE_PERSON_SUCCESS";
const SAVE_PERSON_ERROR = "PEOPLE_EDITOR/SAVE_PERSON_ERROR";

const fetchPersonSuccess = createAction(FETCH_PERSON_SUCCESS);
const fetchPersonError = createAction(FETCH_PERSON_ERROR);
const fetchPerson = createAction(
  FETCH_PERSON,
  null,
  (personId) => ({
    method: "GET",
    view: "peopleEditor",
    path: `people/${personId}`,
    params: {
      accountId: getAccountId,
      orgId: getOrgId,
    },
    onSuccess: fetchPersonSuccess,
    onError: fetchPersonError,
  }),
);
const initNewPerson = createAction(INIT_NEW_PERSON);

const setUserInfoField = createAction(SET_USER_INFO_FIELD);

const savePersonSuccess = createAction(SAVE_PERSON_SUCCESS);
const savePersonError = createAction(SAVE_PERSON_ERROR);
const savePerson = createAction(
  SAVE_PERSON,
  null,
  ({ person, isNew }) => ({
    method: isNew ? "POST" : "PUT",
    view: "peopleEditor",
    path: `people/${person.id}`,
    params: {
      accountId: getAccountId,
      orgId: getOrgId,
    },
    needsAuthorization: true,
    body: {
      person: getData,
    },
    onSuccess: fetchPersonSuccess,
    onError: fetchPersonError,
  }),
);

export {
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_ERROR,
  INIT_NEW_PERSON,
  SET_USER_INFO_FIELD,
  SAVE_PERSON,
  SAVE_PERSON_SUCCESS,
  SAVE_PERSON_ERROR,
  fetchPerson,
  fetchPersonSuccess,
  fetchPersonError,
  initNewPerson,
  setUserInfoField,
  savePerson,
  savePersonSuccess,
  savePersonError,
};

