import React from "react";
import PropTypes from "prop-types";
import { omit } from "lodash";

import { EmailLink } from "../../../components/WebEmailLink";

import {
  getFieldText,
  formatTextStyle,
  EXCLUDE_WEB_EMAIL_STYLE_PROPS,
} from "./utils";
import { textPropTypes, defaultTextProps } from "./propTypes";

const Email = ({ style, reference, userInfo, baseTextStyle }) => {
  const emailAddress = getFieldText(userInfo, reference);

  return emailAddress ? (
    <EmailLink
      email={emailAddress}
      textStyle={formatTextStyle(
        style,
        omit(baseTextStyle, EXCLUDE_WEB_EMAIL_STYLE_PROPS),
      )}
    />
  ) : null;
};

Email.propTypes = {
  ...textPropTypes,
  reference: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
};

Email.defaultProps = {
  ...defaultTextProps,
};

export default Email;
