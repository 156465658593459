import React from "react";
import PropTypes from "prop-types";
import { clone } from "lodash";

import { extractTextStyle } from "./utils";

import Container from "./Container";
import Paragraph from "./Paragraph";
import TextComponent from "./TextComponent";
import Field from "./Field";
import Email from "./Email";
import ImageComponent from "./ImageComponent";
import Anchor from "./Anchor";

const typeMap = {
  container: Container,
  paragraph: Paragraph,
  field: Field,
  text: TextComponent,
  image: ImageComponent,
  anchor: Anchor,
  email: Email,
};

const CardComponent = ({ userInfo, content, baseTextStyle }) => {
  const { type, children, style, ...otherContentProps } = content;
  const Wrapper = typeMap[type] || Container;
  const newBaseTextStyle = {
    ...baseTextStyle,
    ...extractTextStyle(style || {}),
  };

  return (
    <Wrapper
      userInfo={userInfo}
      style={style}
      baseTextStyle={newBaseTextStyle}
      {...otherContentProps}
    >
      {(children || []).map(child => (
        <CardComponent
          key={child.id}
          content={child}
          userInfo={userInfo}
          baseTextStyle={clone(newBaseTextStyle)}
        />
      ))}
    </Wrapper>
  );
};

CardComponent.propTypes = {
  userInfo: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  baseTextStyle: PropTypes.object,
};
CardComponent.defaultProps = {
  baseTextStyle: {},
};

export default CardComponent;
