import PropTypes from "prop-types";
import Immutable from "seamless-immutable";

import UserInfo from "./userInfo";

class Person {
  constructor({
    id,
    orgId,
    userInfo,
    source,
  }) {
    return Immutable.from({
      id,
      orgId,
      userInfo: new UserInfo(userInfo),
      source,
    }, { prototype: this.constructor.prototype });
  }
}

const personPropType = PropTypes.instanceOf(Person);

export {
  Person as default,
  personPropType,
};
