import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControl, ControlLabel, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";


class ImageDetails extends Component {
  dropzoneRef = null;

  static propTypes = {
    selectedComponent: PropTypes.object,
    setImage: PropTypes.func.isRequired,
    setStyle: PropTypes.func,
    hideDimensions: PropTypes.bool,
  };

  handleFileDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    if (acceptedFiles.length) {
      const newFile = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.props.setImage(reader.result, true);
      };
      reader.readAsDataURL(newFile);
    }
  };

  renderDimensions() {
    const {
      selectedComponent: {
        style: {
          width,
          height,
        },
      },
      setStyle,
    } = this.props;

    return (
      <Fragment>
        <FormGroup className="card_editor_inline_group">
          <ControlLabel>Width</ControlLabel>
          <FormControl
            className="card_editor_value"
            type="text"
            value={width}
            onChange={e => setStyle({width: e.target.value})}
          />
        </FormGroup>
        <FormGroup className="card_editor_inline_group">
          <ControlLabel>Height</ControlLabel>
          <FormControl
            className="card_editor_value"
            type="text"
            value={height}
            onChange={e => setStyle({height: e.target.value})}
          />
        </FormGroup>
      </Fragment>
    );
  }
  render() {
    const {
      selectedComponent,
      hideDimensions,
      setStyle,
    } = this.props;

    return (
      <Fragment>
        <Dropzone
          multiple={false}
          ref={ref => {
            this.dropzoneRef = ref
          }}
          onDrop={this.handleFileDrop}
        >
          <p>Drop files here</p>
        </Dropzone>
        <Button onClick={() => { this.dropzoneRef.open() }}>
          Select File
        </Button>
        {!hideDimensions && !!selectedComponent && setStyle && this.renderDimensions()}
      </Fragment>
    );
  }
}

export default ImageDetails;
