import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import SmartBanner from "react-smartbanner";
import { connect } from "react-redux";
import { fetchUserCard } from "../actions/userCardActions";
import { getUserCard, getError } from "../selectors/userCardSelector";
import DisplayCard from "../components/DisplayCard";
import { getOS } from "../helpers/browser";

import "react-smartbanner/dist/main.css";
import "../App.css";

const showBanner = false; // Hide banner till app is on app store

const getNameFromUserInfo = userInfo => {
  if (userInfo && userInfo.name) {
    const { first, surname } = userInfo.name;

    return `${first ? `${first} ` : ''}${surname || ''}`;
  }

  return '';
};

// TODO: Add ios, android etc when app is available. Also update index.html tags
const SupportedAppOS = [];

const getBannerOS = () => {
  const osDetails = getOS();

  if (osDetails && osDetails.name) {
    const matchOS = osDetails.name.toLowerCase();

    return SupportedAppOS.find(os => os === matchOS) || '';
  }

  return '';
};

const DisplayCardPage = ({ fetch, userCardInfo, error, match }) => {
  useEffect(() => {
    fetch(match.params.id);
  }, [fetch, match.params.id]);

  const renderMetadata = () => {
    const name = userCardInfo ? getNameFromUserInfo(userCardInfo.userInfo) : '';
    const title = `Vbiz${name ? ` - ${name}` : ''}`;

    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  };

  const renderSignUpButton = () => (
    <button className="primary-button display-card-page-button">
      <a href="http://www.vbizapp.com" target="_blank" rel="noopener noreferrer">
        VBiz Sign Up
      </a>
    </button>
  );

  let content;

  if (userCardInfo) {
    const { userInfo, template } = userCardInfo;

    content = <DisplayCard template={template.front} userInfo={userInfo} />;
  } else if (error) {
    // TODO: Error with link to home page
    content = <div>error here...</div>;
  } else {
    content = <div>Spinner here...</div>;
  }

  return (
    <div className="page-container">
      {renderMetadata()}
      {showBanner ? <SmartBanner title="VBiz" force={getBannerOS()} /> : renderSignUpButton()}
      {content}
      {renderSignUpButton()}
    </div>
  )
};

DisplayCardPage.propTypes = {
  fetch: PropTypes.func.isRequired,
  userCardInfo: PropTypes.object,
  error: PropTypes.object,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { match }) => ({
  userCardInfo: getUserCard(state),
  error: getError(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => {
    dispatch(fetchUserCard(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayCardPage);
