import { connect } from "react-redux";

import { getIsLoggedIn } from "../selectors/userAccount";
import { setLoggedIn } from "../actions/userAccount";

import LoginPage from "../components/LoginPage";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  sendCredentials: () => {
    // TODO: Send request - then get userId in response
    dispatch(setLoggedIn(true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);

