import React from "react";
import PropTypes from "prop-types";
import { Treebeard, decorators as defaultDecorators, theme } from "react-treebeard";

import Container from "../Container";

import { cardPickerContainer } from "../../containers/cardPickerContainer";

import { cardSidePropType } from "../../types/cardSide";

import "../../css/ComponentTree.css";

const childToItem = ({ type, id, children }, selectedId, setSelected) => {
  return {
    name: `${id}: ${type}${id === selectedId ? ' (Selected)' : ''}`,
    id,
    toggled: true,
    children: children.length > 0 ? children.map(c => childToItem(c, selectedId, setSelected)) : null,
    setSelected,
    selected: id === selectedId,
  };
};

const cardSideToTreeData = ({ children }, selectedId, setSelected) => {
  return {
    name: `root: container ${!selectedId ? ' (Selected)' : ''}`,
    toggled: true,
    children: children.length > 0 ? children.map(c => childToItem(c, selectedId, setSelected)) : null,
    setSelected,
    selected: !selectedId,
  };
};

const treeDecorators = {
  ...defaultDecorators,
  Container: (props) => {
    const { node, decorators } = props;
    return (
      <div
        style={props.style.link}
        className={node.selected ? "component_tree_selected" : ""}
        onClick={() => {
          node.setSelected(node.id || 0);
        }
      }>
        <decorators.Toggle
          style={props.style.toggle}
          onClick={props.onClick}
        />
        <decorators.Header {...props} />
      </div>
    )
  },
  Header: (props) => (
    <div className="component_tree_header_container">
      <div className="component_tree_header_name">
        {props.node.name}
      </div>
    </div>
  ),
};

const treeStyle = {
  ...theme,
  tree: {
    ...theme.tree,
    base: {
      ...theme.tree.base,
      backgroundColor: 'white',
    },
  },
};

export const ComponentTree = ({
  className,
  cardSide,
  selectedItem,
  setSelectedItem
}) => {
  return (
    <Container className={className} >
      <Treebeard
        data={cardSideToTreeData(cardSide, selectedItem, setSelectedItem)}
//        onToggle={(node, toggled) => setSelectedItem(node.id || 0)}
        decorators={treeDecorators}
        style={treeStyle}
      />
    </Container>
  );
};

ComponentTree.propTypes = {
  className: PropTypes.string,
  selectedItem: PropTypes.number,
  cardSide: cardSidePropType,
  setSelectedItem: PropTypes.func.isRequired,
};

export default cardPickerContainer(ComponentTree);
