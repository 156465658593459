import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../css/Container.css";

const Container = ({ className, children }) => (
  <div className={classNames(className, "container_container")}>
    {children}
  </div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Container;
