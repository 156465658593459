import { put, call, select, takeEvery } from "redux-saga/effects";

import { FETCH_CARD_LIST } from "../actions/cards";
import { FETCH_CARD, SAVE_CARD } from "../actions/cardEditor";
import { FETCH_PEOPLE } from "../actions/people";
import { FETCH_PERSON, SAVE_PERSON } from "../actions/peopleEditor";
import { FETCH_USER_CARD } from "../actions/userCardActions";
import { FETCH_USER_ACCOUNT } from "../actions/userAccount";
import { getAuthorization } from "../selectors/userAccount";

import config from "../config/config";

const BASE_URL = config.baseUrl;

const CARD_RF = {
  id: 33445,
  name: "Real Flame 1",
  htmlOnly: false,
  front: {
    style: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      justifyContent: "space-around",
      alignItems: "stretch",
      color: "black",
      fontFamily: "Arial, Helvetica, sans-serif",
      margin: "0",
      padding: ".05rem",
    },
    children: [{
      id: 11111,
      type: "container",
      style: {
        flexGrow: 2,
        paddingLeft: "0.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      },
      children: [{
        id: 12111,
        type: "paragraph",
        style: {
          fontWeight: "bold",
        },
        children: [{
          id: 12211,
          type: "field",
          reference: "firstName",
        }, {
          id: 12212,
          type: "text",
          content: " ",
        }, {
          id: 12213,
          type: "field",
          reference: "lastName",
        }],
      }, {
        // Address container
        id: 21111,
        type: "container",
        children: [{
          id: 21112,
          type: "paragraph",
          children: [{
            id: 21152,
            type: "field",
            reference: "street",
          }],
        }, {
          id: 43211,
          type: "paragraph",
          children: [{
            id: 43220,
            type: "field",
            reference: "suburb",
          }, {
            id: 43221,
            type: "text",
            content: " ",
          }, {
            id: 43222,
            type: "field",
            reference: "state",
          }, {
            id: 43223,
            type: "text",
            content: " ",
          }, {
            id: 43224,
            type: "field",
            reference: "postcode",
          }],
        }],
      }, {
        // Contact container
        id: 28112,
        type: "container",
        style: {
          marginLeft: 0,
        },
        children: [{
          // Mobile container
          id: 24111,
          type: "paragraph",
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          },
          children: [{
            id: 25111,
            type: "text",
            content: "m",
            style: {
              minWidth: "2rem",
            },
          }, {
            id: 25112,
            type: "field",
            reference: "mobilePhone",
          }],
        }, {
          // phone container
          id: 24812,
          type: "paragraph",
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          },
          children: [{
            id: 25812,
            type: "text",
            content: "t",
            style: {
              minWidth: "2rem",
            },
          }, {
            id: 25813,
            type: "field",
            reference: "phone",
          }],
        }, {
          // fax container
          id: 24913,
          type: "paragraph",
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          },
          children: [{
            id: 25915,
            type: "text",
            content: "f",
            style: {
              minWidth: "2rem",
            },
          }, {
            id: 25917,
            type: "field",
            reference: "fax",
          }],
        }],
      }, {
        // Company URL
        id: 21113,
        type: "anchor",
        reference: "companyUrl",
        target: "_blank",
      }],
    }, {
      // Logo container
      id: 11112,
      type: "container",
      style: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      children: [{
        // TODO: Image type component
        // TODO: Should have image source not just base64
        id: 556671,
        type: "image",
        image: "http://www.realflame.com.au/client/flame/img/logo-1.jpg",
        style: {
          width: 80,
          height: 50,
        },
      }],
    }],
  },
  back: {
    style: {
      backgroundColor: "white",
      color: "black",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    children: [{
      id: 666,
      type: "paragraph",
      content: "real flame",
      style: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      },
    }],
  },
};

const CARD_A = {
  id: 22334,
  name: "Card A",
  htmlOnly: false,
  front: {
    style: {
      backgroundColor: "#ccff66",
      color: "#666633",
      padding: "0.5rem",
      fontFamily: "Tahoma, Geneva, sans-serif",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-around",
      borderStyle: "solid",
      borderWidth: "3px",
    },
    children: [{
      id: 1,
      type: "paragraph",
      children: [{
        id: 2,
        type: "field",
        reference: "firstName",
      }, {
        id: 3,
        type: "text",
        content: " ",
      }, {
        id: 4,
        type: "field",
        reference: "lastName",
      }],
    }, {
      id: 22,
      type: "paragraph",
      children: [{
        id: 221,
        type: "field",
        reference: "companyName",
      }],
    }, {
      id: 30,
      type: "paragraph",
      children: [{
        id: 311,
        type: "anchor",
        reference: "companyUrl",
        target: "_blank",
      }],
    }, {
      id: 42,
      type: "paragraph",
      children: [{
        id: 421,
        type: "email",
        reference: "email",
      }],
    }, {
      id: 52,
      type: "paragraph",
      children: [{
        id: 521,
        type: "text",
        content: "Phone: ",
      }, {
        id: 522,
        type: "field",
        reference: "phone",
      }],
    }],
  },
  back: {
    style: {
      backgroundColor: "#ccff66",
      color: "#666633",
      padding: "1rem",
      fontFamily: "Tahoma, Geneva, sans-serif",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    children: [{
      id: 1001,
      type: "paragraph",
      content: "BACK",
    }],
  },
};

const MOCK_DATA = { // eslint-disable-line no-unused-vars
  cardList: {
    cards: [
      CARD_A,
      CARD_RF,
    ],
  },
  cardEditor: CARD_RF,
};

function *fetchData({ url, method, body, headers, needsAuthorization }) {
  let authorization = undefined;
  if (needsAuthorization) {
    authorization = yield select(getAuthorization);
  }

  const params = {
    method: method || "GET",
    body: body && JSON.stringify(body),
    mode: 'cors',
    headers: new Headers({
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": authorization,
      ...headers,
    }),
  };

  console.log(`Sending body: ${params.body}`);

  try {
    const response = yield fetch(url, params);

    // TODO: Convert response??
    console.log("Got response from fetch");

    return yield response.json();
  } catch(e) {
    console.log('Got fetch error');
    // TODO: call error
    throw e;
  }

}

function *makeBody(inputBody) {
  const keyList = Object.keys(inputBody || {});
  const bodyData = {};

  if (keyList.length === 0) {
    return null;
  }


  for (let i=0; i<keyList.length; ++i) {
    const key = keyList[i];
    const selector = inputBody[key];
    const value = yield select(selector);

	bodyData[key] = value;
  }

  return bodyData;
}

function *buildUrl({ view, path, params }) {
  // TODO: Get payload for fetch based on view
  // Get data from redux via selector
  // e.g. yield select(getAbc)
  const paramList = [];
  const keyList = Object.keys(params || {});

  for (let i=0; i<keyList.length; ++i) {
    const key = keyList[i];
    const selector = params[key];
    const value = yield select(selector);

    paramList.push(`${key}=${value}`);
  }

  const paramStr = paramList.join("&");

  return `${BASE_URL}/${path}${paramStr ? `?${paramStr}` : ''}`;
}

function *apiSaga({
  view,
  method,
  path,
  params,
  body,
  needsAuthorization,
  onSuccess: successAction,
  onError: errorAction,
  headers = {},
}) {
  try {
    // TODO: Call api function here - pass in data
//    const response = MOCK_DATA[view];
    const url = yield buildUrl({ view, path, params });
    const builtBody = yield makeBody(body);

    const response = yield call(fetchData, { url, method, body: builtBody, headers, needsAuthorization });
    console.log("Got response");

    yield put(successAction(response));
  } catch(error) {
    console.log(`Calling error action: ${error}`);
    yield put(errorAction(error));
  }
}

const checkMetadata = ({ view, path, onSuccess, onError }) => !!view && !!path && !!onSuccess && !!onError;

function *callFetch(action) {
  if (action.meta && checkMetadata(action.meta)) {
    console.log("Got action with meta data");

    yield apiSaga(action.meta);
  } else {
    console.log("No metadata, stopping here");
  }
}

export default function *watchFetch() {
  yield takeEvery(
    [
      FETCH_CARD_LIST,
      FETCH_CARD,
      SAVE_CARD,
      FETCH_PEOPLE,
      FETCH_PERSON,
      SAVE_PERSON,
      FETCH_USER_CARD,
      FETCH_USER_ACCOUNT,
    ],
    callFetch
  );
}

