import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { peopleListPropType } from "../types/peopleList";
import { errorPropType } from "../types/error";

const PeopleContent = ({ addNewPerson, peopleList: { people }, match }) => {
  const handleNewClick = (e) => {
    e.preventDefault();

    addNewPerson();
  };

  let list;

  if (!people || people.length === 0) {
    list = <p>No people. Click to add some</p>;
  } else {
    list = (
      <ul>
        {
          people.map((person) =>
              <li key={person.id}>
                <Link to={`${match.url}/${person.id}`}>
                  {`Person ID ${person.id}`}
                </Link>
              </li>
          )
        }
        </ul>
    );
  }

  return (
    <div>
      <h3>People</h3>
      {list}
      <Button onClick={handleNewClick}>New</Button>
    </div>
  );
};

const People = ({ fetch, data, error, ...otherProps }) => {
  useEffect(() => {
    fetch();
  }, [fetch]);

  if (error) {
    return(
      <div>
        <h3>Got error</h3>
      </div>
    );
  } else if (data) {
    return (
      <PeopleContent peopleList={data} {...otherProps} />
    );
  }

  return (
    <div>
      <h3>Fetching...</h3>
    </div>
  );
};

People.displayName = "People";
People.propTypes = {
  data: peopleListPropType,
  error: errorPropType,
  fetch: PropTypes.func.isRequired,
};

export default People;
