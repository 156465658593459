import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";
import { merge, clone } from "lodash";

import {
  FETCH_CARD,
  FETCH_CARD_SUCCESS,
  FETCH_CARD_ERROR,
  SAVE_CARD,
  SAVE_CARD_SUCCESS,
  SAVE_CARD_ERROR,
  SET_CARD,
  SET_NAME,
  INIT_NEW_CARD,
  SET_FIELD_TYPE,
  SET_TEXT_CONTENT,
  SET_STYLE,
  SET_IMAGE,
  SET_BACKGROUND_IMAGE,
  SET_LINEAR_GRADIENT,
  ADD_ITEM,
  DELETE_ITEM,
} from "../actions/cardEditor";

import Card from "../types/card";
import CardComponent from "../types/cardComponent";

// TODO: Make this a real method or put in reducer
const getNewId = () => Math.floor(Math.random() * 100000);

const blankCardSide = {
  style: {
    display: "flex",
  },
  children: [],
};

const initialState = Immutable.from({
  isFetching: false,
  error: null,
  data: null,
  isModified: false,
  isNew: false,
  isSaving: false,
  saveError: null,
  savedData: null,
  nextId: 1,
});

const getChildPath = (child, id, curPath) => {
  if (!id || child.id === id) {
    // Set data
    return true;
  } else if (child.children && child.children.length > 0) {
    let result = false;
    for (var i=0; !result && i < child.children.length; ++i) {
      result = getChildPath(child.children[i], id, curPath);
      if (result) {
        curPath.unshift("children", `${i}`);
      }
    }
    return result;
  }
  return false;
};

const getItemPath = (state, { id, cardSide, item }) => {
  let itemPath = null;
  const newCard = state.data;
  const childPath = [];
  const foundChild = getChildPath(newCard[cardSide], id, childPath);

  if (foundChild) {
    itemPath = childPath;
    itemPath.unshift(cardSide);
    itemPath.push(item);
  }

  return itemPath;
};

const setUpdatedItem = (state, payload, itemKey) => {
  let newState = state;
  const { id, cardSide } = payload;

  const itemPath = getItemPath(state, {
    id,
    cardSide,
    item: itemKey,
  });

  if (itemPath) {
    // Update
    newState = state.merge({
      data: state.data.setIn(itemPath, payload[itemKey]),
      isModified: true,
    });
  }

  return newState;
};

export default handleActions({
  [FETCH_CARD]: state => state.merge({
    isFetching: true,
    error: null,
    data: null,
  }),
  [FETCH_CARD_ERROR]: (state, { payload }) => state.merge({
    isFetching: false,
    error: payload,
    data: null,
  }),
  [FETCH_CARD_SUCCESS]: (state, { payload }) => {
    const newCard = new Card(payload);

    // TODO: Go through new card and get highest id
	const highestId = getNewId();

	return state.merge({
      isFetching: false,
      error: null,
      data: newCard,
      isModified: false,
      isNew: false,
      nextId: highestId + 1,
    });
  },
  [SAVE_CARD]: state => state.merge({
    isSaving: true,
    saveError: null,
    savedData: null,
  }),
  [SAVE_CARD_ERROR]: (state, { payload }) => state.merge({
    isSaving: false,
    saveError: payload,
    savedData: null,
  }),
  [SAVE_CARD_SUCCESS]: (state, { payload }) => state.merge({
    isSaving: false,
    saveError: null,
    savedData: new Card(payload),
    data: new Card(payload),
    isModified: false,
    isNew: false,
  }),
  [SET_FIELD_TYPE]: (state, { payload }) => {
    const { id, type, cardSide } = payload;
    let newState = state;

    // Find field
    const newCard = state.data;
    const childPath = [];
    const foundChild = getChildPath(newCard[cardSide], id, childPath);
    if (foundChild) {
      childPath.unshift(cardSide);
      childPath.push("reference");

      // Update
      newState = state.merge({
        data: newCard.setIn(childPath, type),
        isModified: true,
      });
    }

    return newState;
  },
  [SET_TEXT_CONTENT]: (state, { payload }) => {
    return setUpdatedItem(state, payload, "content");
  },
  [SET_STYLE]: (state, { payload }) => {
    const { id, cardSide, style } = payload;
    let newState = state;
    const curCard = state.data;

    // Find component
    const childPath = [];
    const foundChild = getChildPath(curCard[cardSide], id, childPath);
    if (foundChild) {
      childPath.unshift(cardSide);
      childPath.push("style");

      // Get current style
      const curStyle = curCard.getIn(childPath);
      const newStyle = curStyle.merge(style);

      newState = state.merge({
        data: curCard.setIn(childPath, newStyle),
        isModified: true,
      });
    }

    return newState;
  },
  [SET_IMAGE]: (state, { payload }) => {
    const inputPayload = {
      ...payload,
      image: payload.isBase64 ? { base64: payload.image } : payload.image,
    };

    return setUpdatedItem(state, inputPayload, "image");
  },
  [SET_BACKGROUND_IMAGE]: (state, { payload }) => {
    const inputPayload = {
      ...payload,
      backgroundImage: payload.isBase64 ? { base64: payload.image } : payload.image,
      linearGradient: null,
    };
    console.log("Setting background image", payload);

    return setUpdatedItem(state, inputPayload, "backgroundImage");
  },
  [SET_LINEAR_GRADIENT]: (state, { payload }) => {
    const inputPayload = {
      ...payload,
      linearGradient: { ...payload },
      backgroundImage: null,
    };
    console.log("Setting linear gradient", payload);

    return setUpdatedItem(state, inputPayload, "linearGradient");
  },
  [ADD_ITEM]: (state, { payload }) => {
    const { parent, cardSide, item } = payload;
    let newState = state;

    // Find parent
    const parentPath = [];
    const foundParent = (parent > 0) ? getChildPath(state.data[cardSide], parent, parentPath) : true;

    if (foundParent) {
      // Get children of parent
      parentPath.unshift(cardSide);
      parentPath.push("children");
      const parentChildren = state.data.getIn(parentPath, []);

      // Add new item to children array
      const newChildren = parentChildren.concat(
        new CardComponent(merge(clone(item), { id: state.nextId }))
      );

      newState = state.merge({
        data: state.data.setIn(parentPath, newChildren),
        isModified: true,
        nextId: state.nextId + 1,
      });
    }

    return newState;
  },
  [DELETE_ITEM]: (state, { payload }) => {
    const { id, cardSide } = payload;
    let newState = state;

	// Find parent
    const childPath = [];
    const foundChild = getChildPath(state.data[cardSide], id, childPath);

    if (foundChild) {
      // Remove item
      childPath.unshift(cardSide);

      const childPathId = childPath.pop(); // eslint-disable-line no-unused-vars
      const curChildren = state.data.getIn(childPath);
      const newChildren = curChildren.filter(child => child.id !== id);

      newState = state.merge({
        data: state.data.setIn(childPath, newChildren),
        isModified: true,
      });
    }

    return newState;
  },
  [INIT_NEW_CARD]: (state, { payload }) => {
    const newId = payload;
    const newCard = new Card({
      id: newId,
      name: "new template",
      front: blankCardSide,
      back: blankCardSide,
    });

    console.log(`Init new card ${newId}`);

    return state.merge({
      isFetching: false,
      error: null,
      data: newCard,
      isModified: true,
      isNew: true,
      isSaving: false,
      saveError: null,
      savedData: null,
      nextId: 5,
    });
  },
  [SET_CARD]: (state, { payload }) => state.merge({
    data: payload,
  }),
  [SET_NAME]: (state, { payload }) => {
    let newState = state;

    if (state.data && state.data.name !== payload) {
      const newCard = state.data.merge({ name: payload });

      newState = state.merge({
        data: newCard,
        isModified: true,
      });
    }

    return newState;
  }
}, initialState);
