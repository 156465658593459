import React from "react";
import PropTypes from "prop-types";
import { View, ImageBackground } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { pick } from "lodash";

import { extractViewStyle } from "./utils";
import { childrenPropType } from "./propTypes";

const VALID_VIEW_PROPS = ["onClick"];

const HORIZONTAL_GRADIENT_PROPS = {
  start: { x: 0, y: 0 },
  end: { x: 1, y: 0 },
};

const GradientContainer = ({ horizontal, colors, style, ...viewProps }) => {
  // TODO: Handle rgb(R, G, B) and rgba(R, G, B, A) here
  const colorList = colors.split(",").map(s => s.trim());
  const directionProps = horizontal ? HORIZONTAL_GRADIENT_PROPS : {};

  return (
    <LinearGradient
      colors={colorList}
      style={extractViewStyle(style)}
      {...directionProps}
      {...pick(viewProps, VALID_VIEW_PROPS)}
    />
  );
};

GradientContainer.propTypes = {
  horizontal: PropTypes.bool,
  colors: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

const Container = ({
  style,
  children,
  backgroundImage,
  linearGradient,
  ...viewProps
}) => {
  if (linearGradient) {
    return (
      <GradientContainer style={style} {...linearGradient} {...viewProps} />
    );
  }

  const Wrapper = backgroundImage ? ImageBackground : View;
  const wrapperProps = {
    style: extractViewStyle(style),
    ...pick(viewProps, VALID_VIEW_PROPS),
  };

  if (backgroundImage) {
    const imageSource = {
      uri: backgroundImage.base64 || backgroundImage,
    };
    wrapperProps.source = imageSource;
    wrapperProps.resizeMode = "cover";
    wrapperProps.style = {
      width: "100%",
      height: "100%",
      ...wrapperProps.style,
    };
  }

  return <Wrapper {...wrapperProps}>{children}</Wrapper>;
};

Container.propTypes = {
  style: PropTypes.object,
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linearGradient: PropTypes.object,
  children: childrenPropType.isRequired,
};
Container.defaultProps = {
  style: {},
};

export default Container;
