import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import ImageDetails from "./ImageDetails";
import LinearGradientDetails from "./LinearGradientDetails";

import "../../css/CardEditor.css";

// Local state to determine image or test
// If image the prop backgroundImage exists somewhere
const BackgroundImageDetails = ({
  selectedComponent,
  setBackgroundImage,
  setStyle,
  setLinearGradient,
}) => {
  const [showImageSelector, setShowImage] = useState(false);

  console.log('background image', selectedComponent);
  // TODO: Use react-native-linear-gradient component
  // Options - colour list, direction
  const { linearGradient } = (selectedComponent || {});

  const content = showImageSelector ? (
    <ImageDetails
      selectedComponent={selectedComponent}
      setImage={setBackgroundImage}
      setStyle={setStyle}
      hideDimensions
    />
  ) : (
    <LinearGradientDetails
      onChange={
        ({ colors, horizontal }) => {
          setLinearGradient({ colors, horizontal })
        }
      }
      horizontal={Boolean(linearGradient && linearGradient.horizontal)}
      colors={(linearGradient && linearGradient.colors) || ""}
    />
  );

  return (
    <Fragment>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Text</ControlLabel>
        <FormControl
          type="radio"
          checked={!showImageSelector}
          onChange={() => setShowImage(false)}
        />
        <ControlLabel>Image</ControlLabel>
        <FormControl
          type="radio"
          checked={showImageSelector}
          onChange={() => setShowImage(true)}
        />
      </FormGroup>
      {content}
    </Fragment>
  );
};

BackgroundImageDetails.propTypes = {
  selectedComponent: PropTypes.any,
  setBackgroundImage: PropTypes.func.isRequired,
  setStyle: PropTypes.func.isRequired,
  setLinearGradient: PropTypes.func.isRequired,
};

export default BackgroundImageDetails;
