import { all } from "redux-saga/effects";
import fetchWatcher from "./fetchWatcher";
import addCardWatcher from "./addCardWatcher";
import saveWatcher from "./saveWatcher";
import editorWatcher from "./editorWatcher";
import loginWatcher from "./loginWatcher";

export default function *rootSaga() {
  yield all([
    fetchWatcher(),
    addCardWatcher(),
    saveWatcher(),
    editorWatcher(),
    loginWatcher(),
  ]);
}
