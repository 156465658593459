import { connect } from "react-redux";

import { getSelectedItem } from "../selectors/editorSettings";
import { setSelectedItem } from "../actions/editorSettings";
import { getCardSide } from "../selectors/wireFrame";

const mapStateToProps = (state) => ({
  selectedItem: getSelectedItem(state),
  cardSide: getCardSide(state),
});

const mapDispatchToProps = dispatch => ({
  setSelectedItem: item => {
    dispatch(setSelectedItem(item));
  },
});

export const cardPickerContainer = connect(mapStateToProps, mapDispatchToProps);
