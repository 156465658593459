import { connect } from "react-redux";

import { getData, getError, getIsModified, getIsNew } from "../selectors/cardEditor";
import { fetchCard, setCard, saveCard, deleteItem, setName } from "../actions/cardEditor";
import { setSide } from "../actions/editorSettings";
import { isEditorSideFront, getSelectedItem } from "../selectors/editorSettings";

import CardEditor from "../components/CardEditor";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  card: getData(state),
  error: getError(state),
  isModified: getIsModified(state),
  isNew: getIsNew(state),
  isEditorSideFront: isEditorSideFront(state),
  selectedItem: getSelectedItem(state),
});

const mapDispatchToProps = dispatch => ({
  saveCard: (data, isNew) => {
    dispatch(saveCard({
      card: data,
      isNew,
    }));
  },
  setCardData: data => {
    dispatch(setCard(data));
  },
  fetch: id => {
    dispatch(fetchCard(id));
  },
  setSide: showFront => {
    dispatch(setSide(showFront));
  },
  deleteItem: ({ id, cardSide })   => {
    dispatch(deleteItem({ id, cardSide }));
  },
  setName: name => {
    dispatch(setName(name));
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  saveCard: () => {
    dispatchProps.saveCard(stateProps.card, stateProps.isNew);
  },
  deleteItem: () => {
    const id = stateProps.selectedItem;

    id && dispatchProps.deleteItem({
      id,
      cardSide: stateProps.isEditorSideFront ? "front" : "back",
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CardEditor);

