import { createSelector } from "reselect";

const getCardEditor = state => state.cardEditor;

export const getIsFetching = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.isFetching
);

export const getData = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.data
);

export const getError = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.error
);

export const getIsModified = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.isModified
);

export const getIsNew = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.isNew
);

export const getIsSaving = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.isSaving
);

export const getSavedData = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.SavedData
);

export const getSaveError = createSelector(
  getCardEditor,
  cardEditor => cardEditor && cardEditor.SaveError
);

export const getLatestId = createSelector(
  getCardEditor,
  cardEditor => {
    return (cardEditor.nextId > 0) ? (cardEditor.nextId - 1) : 0;
  }
);
