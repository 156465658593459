import React from "react";
import PropTypes from "prop-types";
import { View, Text, StyleSheet } from "react-native";
import {
  getDefaultPadding,
  getFieldFontSize,
  HEADER_COLOR,
} from "../utils/styleUtils";
import { WebLink, EmailLink } from "./WebEmailLink";
import PhoneNumber from "./PhoneNumber";
import IconButton from "./common/IconButton";

import { userInfoPropType } from "../types/userInfo";

const styles = StyleSheet.create({
  container: {
    padding: getDefaultPadding(),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingBottom: 3 * getDefaultPadding(),
  },
  field: {
    marginBottom: getDefaultPadding(),
  },
  fieldText: {
    fontSize: getFieldFontSize(),
  },
  categories: {
    alignSelf: "stretch",
  },
  header: {
    marginTop: getDefaultPadding(),
    paddingBottom: getDefaultPadding(),
    paddingLeft: 4 * getDefaultPadding(),
    borderBottomWidth: 1,
    borderBottomColor: HEADER_COLOR,
    flexDirection: "row",
  },
  headerText: {
    textAlign: "center",
    fontSize: getFieldFontSize(),
    flex: 10,
  },
  categoryButton: {
    marginRight: getDefaultPadding(),
  },
  categoryItem: {
    marginTop: getDefaultPadding(),
  },
  categoryText: {
    textAlign: "center",
    fontSize: getFieldFontSize(),
  },
});

const makeName = ({ name }) =>
  `${name.first || ""}${name.middle || " "}${name.surname || ""}`.trim();

const makeAddressLine2 = ({ suburb, state, postcode }) =>
  `${suburb || ""} ${state || ""} ${postcode || ""}`.trim();

const Address = ({ address }) => {
  const line2 = makeAddressLine2(address);

  return (
    <View style={[styles.address, styles.field]}>
      {address.street && <Text style={styles.fieldText}>{address.street}</Text>}
      {line2.length > 0 && <Text style={styles.fieldText}>{line2}</Text>}
      {address.country && (
        <Text style={styles.fieldText}>{address.country}</Text>
      )}
    </View>
  );
};
Address.propTypes = {
  address: PropTypes.object.isRequired,
};

const TextField = ({ children }) => (
  <View style={styles.field}>
    <Text style={styles.fieldText}>{children}</Text>
  </View>
);
TextField.propTypes = {
  children: PropTypes.node.isRequired,
};

const CategoryItem = ({ category }) => (
  <View style={styles.categoryItem}>
    <Text style={styles.categoryText}>{category}</Text>
  </View>
);
CategoryItem.propTypes = {
  category: PropTypes.string.isRequired,
};

const fieldStyleProps = {
  style: styles.field,
  textStyle: styles.fieldText,
};

const UserInfo = ({ userInfo, categories, showCategories }) => (
  <View style={styles.container}>
    <TextField>{makeName(userInfo)}</TextField>
    {userInfo.jobTitle && <TextField>{userInfo.jobTitle}</TextField>}
    {userInfo.company.name && <TextField>{userInfo.company.name}</TextField>}
    {userInfo.email && (
      <EmailLink email={userInfo.email} {...fieldStyleProps} />
    )}
    {userInfo.phone.mobile && (
      <PhoneNumber
        type="mobile"
        phone={userInfo.phone.mobile}
        {...fieldStyleProps}
      />
    )}
    {userInfo.phone.work && (
      <PhoneNumber
        type="work"
        phone={userInfo.phone.work}
        {...fieldStyleProps}
      />
    )}
    {userInfo.phone.fax && (
      <PhoneNumber type="fax" phone={userInfo.phone.fax} {...fieldStyleProps} />
    )}
    {userInfo.company.url && (
      <WebLink url={userInfo.company.url} {...fieldStyleProps} />
    )}
    {userInfo.address && <Address address={userInfo.address} />}
    {categories && (
      <View style={styles.categories}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Categories</Text>
          {showCategories && (
            <IconButton
              icon="tags"
              style={styles.categoryButton}
              onPress={showCategories}
            />
          )}
        </View>
        {categories.map(category => (
          <CategoryItem key={category} category={category} />
        ))}
      </View>
    )}
  </View>
);

UserInfo.displayName = "UserInfo";
UserInfo.propTypes = {
  userInfo: userInfoPropType.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  showCategories: PropTypes.func,
};

export default UserInfo;
