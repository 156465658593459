import { createSelector } from "reselect";

const getPeople = state => state.people;

const getData = createSelector(
  getPeople,
  people => people && people.data
);

const getIsFetching = createSelector(
  getPeople,
  people => people && people.isFetching
);

const getError = createSelector(
  getPeople,
  people => people && people.error
);

const getPeopleList = createSelector(
  getData,
  data => data && data.people
);

const getNextId = createSelector(
  getPeopleList,
  peopleList => peopleList.reduce((curMax, person) => {
    return (person.id > curMax) ? person.id : curMax;
  }, 0) + 1
);

export {
  getData,
  getError,
  getIsFetching,
  getPeopleList,
  getNextId,
};

