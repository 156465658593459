import React, { useState } from "react";
import PropTypes from "prop-types";
import { ControlLabel, FormControl } from "react-bootstrap";
import IconButton from "../icon-button/IconButton";

import "./editable-field.css";

const EditableField = ({
  label,
  value,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fieldValue, setValue] = useState(value);

  return (
    <div className="editable_field_container">
      <ControlLabel className="editable_field_label">{label}</ControlLabel>
      <div className="editable_field_editor">
        {
          isEditing ? (
            <FormControl
              type="input"
              className="editable_field_value"
              value={fieldValue}
              onChange={e => {
                setValue(e.target.value);
              }}
            />) :
            <span className="editable_field_value">
              {fieldValue}
            </span>
        }
        {
          onUpdate &&
          <IconButton
            className="editable_field_button"
            icon={isEditing ? "save" : "pen"}
            onClick={() => {
              if (isEditing) {
                // TODO: Get value from field
                onUpdate(fieldValue)
              }
              setIsEditing(!isEditing);
            }}
          />
        }
      </div>
    </div>
  );
};

EditableField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditableField;
