import { connect } from "react-redux";

import { getSelectedItem, isEditorSideFront } from "../selectors/editorSettings";
import { getSelectedComponent, getCardSide } from "../selectors/wireFrame";
import {
  setFieldType,
  setTextContent,
  setStyle,
  setImage,
  setBackgroundImage,
  setLinearGradient,
} from "../actions/cardEditor";

import FieldEditor from "../components/CardEditor/FieldEditor";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  selectedItem: getSelectedItem(state),
  selectedComponent: getSelectedComponent(state),
  cardSide: getCardSide(state),
  isFront: isEditorSideFront(state),
});

const mapDispatchToProps = dispatch => ({
  setFieldType: ({ id, type, cardSide }) => {
    dispatch(setFieldType({ id, type, cardSide }));
  },
  setText: ({ id, cardSide, content }) => {
    dispatch(setTextContent({ id, cardSide, content }));
  },
  setStyle: ({ id, cardSide, style }) => {
    dispatch(setStyle({ id, cardSide, style }));
  },
  setImage: payload => {
    dispatch(setImage(payload));
  },
  setBackgroundImage: payload => {
    dispatch(setBackgroundImage(payload));
  },
  setLinearGradient: payload => {
    dispatch(setLinearGradient(payload));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  setFieldType: (type) => {
    dispatchProps.setFieldType({
      id: stateProps.selectedItem,
      type,
      cardSide: stateProps.isFront ? "front" : "back",
    });
  },
  setText: (content) => {
    dispatchProps.setText({
      id: stateProps.selectedItem,
      cardSide: stateProps.isFront ? "front" : "back",
      content,
    });
  },
  setStyle: (style) => {
    dispatchProps.setStyle({
      id: stateProps.selectedItem,
      cardSide: stateProps.isFront ? "front" : "back",
      style,
    });
  },
  setImage: (image, isBase64) => {
    dispatchProps.setImage({
      id: stateProps.selectedItem,
      cardSide: stateProps.isFront ? "front" : "back",
      image,
      isBase64,
    });
  },
  setBackgroundImage: (image, isBase64) => {
    dispatchProps.setBackgroundImage({
      id: stateProps.selectedItem,
      cardSide: stateProps.isFront ? "front" : "back",
      image,
      isBase64,
    });
  },
  setLinearGradient: ({ colors, horizontal }) => {
    dispatchProps.setLinearGradient({
      id: stateProps.selectedItem,
      cardSide: stateProps.isFront ? "front" : "back",
      colors,
      horizontal,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FieldEditor);

