import { connect } from "react-redux";

import { isEditorSideFront } from "../selectors/editorSettings";
import { getSelectedComponent } from "../selectors/wireFrame";
import { setPalletteSelection, setSelectedItem } from "../actions/editorSettings";
import { addItem } from "../actions/cardEditor";

import Pallette from "../components/CardEditor/Pallette";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  selectedComponent: getSelectedComponent(state),
  isFront: isEditorSideFront(state),
});

const mapDispatchToProps = dispatch => ({
  setPalletteSelection: item => {
    dispatch(setPalletteSelection(item));
  },
  setSelectedItem: item => {
    dispatch(setSelectedItem(item));
  },
  addItem: ({ parent, cardSide, item }) => {
    dispatch(addItem({ parent, cardSide, item }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  dropItem: ({ item, parentId }) => {
    dispatchProps.addItem({
      parent: parentId || 0,
      cardSide: stateProps.isFront ? "front" : "back",
      item,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Pallette);

