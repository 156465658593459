import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import userAccount from "./userAccount";
import cards from "./cards";
import cardEditor from "./cardEditor";
import editorSettings from "./editorSettings";
import people from "./people";
import peopleEditor from "./peopleEditor";
import userCard from "./userCardReducer";

const createRootReducer = (history) => combineReducers({
  userAccount,
  cards,
  cardEditor,
  editorSettings,
  people,
  peopleEditor,
  userCard,
  router: connectRouter(history),
});

export default createRootReducer;
