import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "react-bootstrap";
import EditableField from "../editable-field/EditableField";

import { cardPropType } from "../../types/card";

import "../../css/CardEditorSummary.css";

const getSideString = isFront => isFront ? "Front" : "Back";

class CardSummary extends Component {
  static displayName = "CardSummary";
  static propTypes = {
    card: cardPropType.isRequired,
    isEditorSideFront: PropTypes.bool.isRequired,
    isModified: PropTypes.bool.isRequired,
    setSide: PropTypes.func.isRequired,
    saveCard: PropTypes.func.isRequired,
    selectedItem: PropTypes.number.isRequired,
    deleteItem: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
  };

  flipCard = (e) => {
    e.preventDefault();

    const { isEditorSideFront, setSide } = this.props;

    setSide(!isEditorSideFront);
  };

  handleSaveClick = (e) => {
    e.preventDefault();

    const { isModified, saveCard } = this.props;

    isModified && saveCard();
  };

  handleDeleteClick = (e) => {
    e.preventDefault();

    this.props.deleteItem();
  };

  render() {
    const {
      card,
      isEditorSideFront,
      isModified,
      selectedItem,
      setName,
    } = this.props;

    // TODO: Controls at top i.e. save, undo, flip
    return (
      <div className="card_editor_summary_container">
        <div className="card_editor_summary_info">
          <EditableField label="Name" value={card.name} onUpdate={setName} />
          <div>Side: {getSideString(isEditorSideFront)}</div>
        </div>
        <ButtonToolbar>
          <Button onClick={this.flipCard}>Flip</Button>
          <Button disabled={selectedItem === 0} onClick={this.handleDeleteClick}>Delete</Button>
          <Button disabled={!isModified} onClick={this.handleSaveClick}>Save</Button>
          <Button disabled={true}>Undo</Button>
        </ButtonToolbar>
      </div>
    );
  }
}

export default CardSummary;
