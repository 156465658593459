import { put, takeEvery, select } from "redux-saga/effects";
import { push } from "connected-react-router";

import { ADD_NEW_CARD } from "../actions/cards";
import { ADD_PERSON } from "../actions/people";

function *addNewSaga({
  nextId,
  initNew,
  path,
}) {
  try {
    // Get new ID
    const newId = yield select(nextId);
    console.log(`Got new ID: ${newId}`);

    // Set new item
    yield put(initNew(newId));

    // Set path to new item
    const newPath = `${path}/${newId}`;

    yield put(push(newPath));
    console.log(`Put new path - ${newPath}`);
  } catch(error) {
    console.log(`Add new error: ${error}`);
  }
}

const checkMetadata = ({ nextId, initNew, path }) => !!nextId && !!initNew && !!path;

function *addNew(action) {
  console.log("Add new saga");
  if (action.meta && checkMetadata(action.meta)) {
    console.log("Got metadata");
    yield addNewSaga(action.meta);
  } else {
    console.log("No valid metadata for add new");
  }
}

export default function *watchNew() {
  yield takeEvery([ADD_PERSON, ADD_NEW_CARD], addNew);
}

