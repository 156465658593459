import { createSelector } from "reselect";

const getPeopleEditor = state => state.peopleEditor;

const getIsFetching = createSelector(
  getPeopleEditor,
  peopleEditor => peopleEditor.isFetching
);

const getData = createSelector(
  getPeopleEditor,
  peopleEditor => peopleEditor && peopleEditor.data
);

const getError = createSelector(
  getPeopleEditor,
  peopleEditor => peopleEditor && peopleEditor.error
);

const getIsNew = createSelector(
  getPeopleEditor,
  peopleEditor => peopleEditor.isNew
);

const getIsModified = createSelector(
  getPeopleEditor,
  peopleEditor => peopleEditor && peopleEditor.isModified
);

const getIsEditable = createSelector(
  getData,
  person => !!(person && person.isEditable)
);

export {
  getIsFetching,
  getData,
  getError,
  getIsModified,
  getIsEditable,
  getIsNew,
};
