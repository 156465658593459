import React from "react";
import PropTypes from "prop-types";

import { childrenPropType, contentPropType } from "./propTypes";
import Container from "./Container";

const Paragraph = ({ style, content, children, ...otherProps }) => (
  <Container style={style} {...otherProps}>
    {content || children}
  </Container>
);

Paragraph.propTypes = {
  style: PropTypes.object,
  children: childrenPropType,
  content: contentPropType,
};
Paragraph.defaultProps = {
  style: {},
};

export default Paragraph;
