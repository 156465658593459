import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = ({ className, icon, ...buttonProps }) => (
  <Button className={className} {...buttonProps}>
    <FontAwesomeIcon icon={icon} />
  </Button>
);

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default IconButton;
