import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";

import {
  FETCH_PEOPLE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_ERROR,
} from "../actions/people";

import PeopleList from "../types/peopleList";

const initialState = Immutable.from({
  isFetching: false,
  data: null,
  error: null,
});

export default handleActions({
  [FETCH_PEOPLE]: state => state.merge({
    isFetching: true,
    error: null,
    data: null,
  }),
  [FETCH_PEOPLE_ERROR]: (state, { payload }) => state.merge({
    isFetching: false,
    error: payload,
    data: null,
  }),
  [FETCH_PEOPLE_SUCCESS]: (state, { payload }) => state.merge({
    isFetching: false,
    error: null,
    data: new PeopleList(payload),
  }),
}, initialState);
