import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";

import {
  SET_LOGGED_IN,
  LOGOUT,
  FETCH_USER_ACCOUNT,
  FETCH_USER_ACCOUNT_SUCCESS,
  FETCH_USER_ACCOUNT_ERROR,
} from "../actions/userAccount";

const initialState = Immutable.from({
  accountId: null,
  accessToken: null,
  fetching: false,
  accountInfo: null,
});

export default handleActions({
  [SET_LOGGED_IN]: (state, { payload }) => state.merge({
    accountId: payload.accountId,
    accessToken: payload.accessToken,
  }),
  [LOGOUT]: state => state.merge({
    ...initialState
  }),
  [FETCH_USER_ACCOUNT]: state => state.merge({
    fetching: true,
  }),
  [FETCH_USER_ACCOUNT_SUCCESS]: (state, { payload }) => state.merge({
    fetching: false,
    accountInfo: payload,
  }),
  [FETCH_USER_ACCOUNT_ERROR]: (state, { payload }) => state.merge({
    fetching: false,
    error: payload,
  }),
}, initialState);
