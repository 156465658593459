import PropTypes from "prop-types";

const errorPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
]);

export {
  errorPropType,
};
