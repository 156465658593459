import React from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, StyleSheet } from "react-native";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-native-fontawesome";

import {
  ICON_SIZE,
  PRIMARY_TEXT_COLOR,
  DISABLED_TEXT_COLOR,
} from "../../utils/styleUtils";

export const styles = StyleSheet.create({
  container: {
    width: ICON_SIZE,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const IconButton = ({ icon, onPress, style, disabled }) => (
  <TouchableOpacity
    style={[styles.container, style]}
    disabled={disabled}
    onPress={onPress}
  >
    <Icon
      color={disabled ? DISABLED_TEXT_COLOR : PRIMARY_TEXT_COLOR}
      icon={icon}
      size={ICON_SIZE}
    />
  </TouchableOpacity>
);

IconButton.displayName = "IconButton";
IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
};

export default IconButton;
