import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";

import {
  FETCH_PERSON,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_ERROR,
  INIT_NEW_PERSON,
  SET_USER_INFO_FIELD,
  SAVE_PERSON,
  SAVE_PERSON_SUCCESS,
  SAVE_PERSON_ERROR,
} from "../actions/peopleEditor";

import Person from "../types/person";

const initialState = Immutable.from({
  isFetching: false,
  data: null,
  error: null,
  isModified: false,
  isNew: false,
  isSaving: false,
});

export default handleActions({
  [FETCH_PERSON]: state => state.merge({
    isFetching: true,
    error: null,
    data: null,
    isModified: false,
    isNew: false,
  }),
  [FETCH_PERSON_ERROR]: (state, { payload }) => state.merge({
    isFetching: false,
    error: payload,
    data: null,
    isModified: false,
    isNew: false,
  }),
  [FETCH_PERSON_SUCCESS]: (state, { payload }) => state.merge({
    isFetching: false,
    error: null,
    data: new Person(payload),
    isModified: false,
    isNew: false,
  }),
  [INIT_NEW_PERSON]: (state, { payload }) => {
    const newId = payload;
    const emptyPerson = {
      name: {},
      company: {},
      address: {},
      postalAddress: {},
      phone: {},
    };

    return state.merge({
      isFetching: false,
      error: null,
      isModified: false,
      isNew: true,
      data: new Person({
        id: newId,
        source: {
          type: "local",
          id: newId,
        },
        userInfo: emptyPerson,
      }),
    });
  },
  [SET_USER_INFO_FIELD]: (state, { payload }) => {
    const {
      path: userInfoPath,
	  value,
    } = payload;
    let newState = state;

	if (state.data && state.data.userInfo) {
      const fullPath = ["userInfo"].concat(userInfoPath.split("."));

      newState = state.merge({
        data: state.data.setIn(fullPath, value),
        isModified: true,
      });
    }

	return newState;
  },
  [SAVE_PERSON]: (state) => state.merge({
    isSaving: true,
  }),
  [SAVE_PERSON_SUCCESS]: (state, { payload }) => state.merge({
    isSaving: false,
    data: new Person(payload),
    isModified: false,
    isNew: false,
  }),
  [SAVE_PERSON_ERROR]: (state, { payload }) => state.merge({
    isSaving: false,
    error: payload,
  }),
}, initialState);
