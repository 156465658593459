import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageContainer from "./page-container/PageContainer";
import { cardListPropType } from "../types/cardList";
import { errorPropType } from "../types/error";

const CardsContent = ({ addNewCard, data: { cards }, baseUrl }) => {
  const handleNewClick = (e) => {
    e.preventDefault();

    // Call add new card action
    addNewCard();
  };

  let cardList;

  if (cards.length === 0) {
    cardList = <p>No Cards. Click New to create one</p>;
  } else {
    cardList = (
      <ul>
        {
          cards.map(card =>
            <li key={card.id}>
              <Link to={`${baseUrl}/${card.id}`}>
                {`Card ID ${card.id} - ${card.name}`}
                </Link>
            </li>
          )
        }
      </ul>
    );
  }

  return (
    <PageContainer>
      <h3>Cards</h3>
      {cardList}
      <Button onClick={handleNewClick}>New</Button>
    </PageContainer>
  );
};

CardsContent.displayName = "CardsContent";
CardsContent.propTypes = {
  data: cardListPropType.isRequired,
  baseUrl: PropTypes.string.isRequired,
  addNewCard: PropTypes.func.isRequired,
};

const Cards = ({ fetch, data, error, match: { url }, ...otherProps }) => {
  useEffect(() => {
    // Fetch card details
    fetch();
  }, [fetch]);

  let content;

  if (error) {
    content = <div><h3>Got error</h3></div>;
  } else if (data) {
    content = (
      <CardsContent
        data={data}
        baseUrl={url}
        {...otherProps}
      />
    );
  } else {
    content = <div><h3>Fetching..</h3></div>;
  }

	return content;
};

Cards.displayName = "Cards";
Cards.propTypes = {
  data: cardListPropType,
  error: errorPropType,
  match: PropTypes.object.isRequired,
  addNewCard: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

export default Cards;
