import { createAction } from "redux-actions";

export const FETCH_USER_CARD = "USER_CARD_ACTIONS/FETCH_USER_CARD";
export const FETCH_USER_CARD_SUCCESS = "USER_CARD_ACTIONS/FETCH_USER_CARD_SUCCESS";
export const FETCH_USER_CARD_ERROR = "USER_CARD_ACTIONS/FETCH_USER_CARD_ERROR";

export const fetchUserCardSuccess = createAction(FETCH_USER_CARD_SUCCESS);
export const fetchUserCardError = createAction(FETCH_USER_CARD_ERROR);
export const fetchUserCard = createAction(
  FETCH_USER_CARD,
  null,
  (cardId) => ({
    method: "GET",
    view: "card",
    path: `card/${cardId}`,
    headers: {
      "x-vbiz-agent": 1,
    },
    onSuccess: fetchUserCardSuccess,
    onError: fetchUserCardError,
  }),
);

