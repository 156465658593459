import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { AmplifyTheme, withAuthenticator } from "aws-amplify-react";
import CbHeader from "../../containers/cbHeader";
import Home from "../../components/Home";
import Cards from "../../containers/cards";
import CardEditor from "../../containers/cardEditor";
import About from "../../components/About";
import People from "../../containers/people";
import PeopleEditor from "../../containers/peopleEditor";
import Admin from "../../components/Admin";
import { signUpConfig } from "../../app/Vbiz/vbiz/config/authConfig";
import { setLoggedIn } from "../../actions/userAccount";

import { CARD_BUILDER, TEMPLATES, PEOPLE, ADMIN, ABOUT_CARD_BUILDER } from "../../helpers/paths";
import {getIsLoggedIn} from "../../selectors/userAccount";

const CardBuilderPage = (props) => {
  const {
    authData: {
      attributes: { sub: accountId },
      signInUserSession: {
        accessToken: { jwtToken: token },
      },
    },
    setLoggedIn,
    isLoggedIn,
  } = props;

  useEffect(() => {
    setLoggedIn(accountId, token);
  }, [setLoggedIn, accountId, token]);

  if (!isLoggedIn) {
    return (
      <div>Spinner...</div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>VBiz - Card Builder</title>
      </Helmet>
      <CbHeader />
      <Switch>
        <Route exact path={CARD_BUILDER} component={Home} />
        <Route path={`${TEMPLATES}/:id`} component={CardEditor}/>
        <Route path={TEMPLATES} component={Cards} />
        <Route path={`${PEOPLE}/:id`} component={PeopleEditor} />
        <Route path={PEOPLE} component={People} />
        <Route path={ADMIN} component={Admin} />
        <Route path={ABOUT_CARD_BUILDER} component={About} />
      </Switch>
    </div>
  );
};

CardBuilderPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  authData: PropTypes.object.isRequired,
  setLoggedIn: PropTypes.func.isRequired,
};

const theme = {
  ...AmplifyTheme,
  sectionHeader: {
    ...AmplifyTheme.sectionHeader,
  },
};

const authConfig = {
  includeGreetings: false,
  signUpConfig,
  theme,
};

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  setLoggedIn: (accountId, accessToken) => {
    dispatch(setLoggedIn({
      accountId,
      accessToken,
    }))
  }
});

export default withAuthenticator(
  connect(mapStateToProps, mapDispatchToProps)(CardBuilderPage),
  authConfig
);
