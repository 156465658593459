import { createSelector } from "reselect";

const getRouter = state => state.router;

const getRouteLocation = createSelector(
  getRouter,
  router => router && router.location
);

const getRoutePath = createSelector(
  getRouteLocation,
  location => location && location.pathname
);

export {
  getRouteLocation,
  getRoutePath,
};

