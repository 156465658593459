import { createAction } from "redux-actions";

export const SET_LOGGED_IN = "USER_ACCOUNT/SET_LOGGED_IN";
export const LOGOUT = "USER_ACCOUNT/LOGOUT";

export const FETCH_USER_ACCOUNT = "USER_ACCOUNT/FETCH_USER_ACCOUNT";
export const FETCH_USER_ACCOUNT_SUCCESS = "USER_ACCOUNT/FETCH_USER_ACCOUNT_SUCCESS";
export const FETCH_USER_ACCOUNT_ERROR = "USER_ACCOUNT/FETCH_USER_ACCOUNT_ERROR";

export const setLoggedIn = createAction(SET_LOGGED_IN);
export const logOut = createAction(LOGOUT);

export const fetchUserAccountSuccess = createAction(FETCH_USER_ACCOUNT_SUCCESS);
export const fetchUserAccountError = createAction(FETCH_USER_ACCOUNT_ERROR);
export const fetchUserAccount = createAction(
  FETCH_USER_ACCOUNT,
  null,
  (accountId) => ({
    method: "GET",
    view: "userAccount",
    path: `account/${accountId}`,
    needsAuthorization: true,
    onSuccess: fetchUserAccountSuccess,
    onError: fetchUserAccountError,
  })
);
