import { pick, omit, get, isString } from "lodash";

const BASE_SIZE = 10;
const TEXT_ONLY_KEYS = [
  "color",
  "fontWeight",
  "fontSize",
  "fontFamily",
  "textDecorationLine",
  "textAlign",
  "lineHeight",
  "textTransform",
  "letterSpacing",
];
const BASE_TEXT_STYLE_KEYS = [
  ...TEXT_ONLY_KEYS,
  "borderColor",
  "borderWidth",
  "borderStyle",
  "minWidth",
];
const TEXT_STYLE_KEYS = [
  ...BASE_TEXT_STYLE_KEYS,
  "margin",
  "marginLeft",
  "marginRight",
  "marginTop",
  "marginBottom",
  "padding",
  "paddingLeft",
  "paddingRight",
  "paddingTop",
  "paddingBottom",
];
export const EXCLUDE_WEB_EMAIL_STYLE_PROPS = ["color", "textDecorationLine"];

const getCardTextBaseSize = () => BASE_SIZE;

// options: 1rem, 1em, 1px, auto
const convertUnitToSize = unitStr => {
  let val = 0;

  // rem
  const remIndex = `${unitStr || ""}`.indexOf("rem");
  const emIndex = `${unitStr || ""}`.indexOf("em");
  const pxIndex = `${unitStr || ""}`.indexOf("px");
  const percentIndex = `${unitStr || ""}`.indexOf("%");

  if (remIndex > 0) {
    val = parseFloat(unitStr.substr(0, remIndex), 10) * getCardTextBaseSize();
  } else if (emIndex > 0) {
    val = parseFloat(unitStr.substr(0, emIndex), 10) * getCardTextBaseSize();
  } else if (pxIndex > 0) {
    val = parseFloat(unitStr.substr(0, pxIndex), 10);
  } else if (percentIndex > 0) {
    val = unitStr;
  } else {
    val = parseFloat(unitStr, 10) || 0;
  }

  return val;
};

const VALID_BORDER_STYLE = ["solid", "dotted", "dashed"];

const convertBorderStyle = borderStyle => {
  if (VALID_BORDER_STYLE.includes(borderStyle)) {
    return { borderStyle };
  }

  return {
    borderStyle: "solid",
    borderWidth: 0,
  };
};

const strToInt = key => val => ({
  [key]: parseInt(val, 10),
});

// Margin options
// 1rem: all 4 options
// 1rem 2rem: vertial | horizontal
// 1rem 2rem 3rem: top | horizontal | bottom
// 1rem 2rem 3rem 4rem: top | right | bottom | left
const convertSpacing = type => value => {
  const spaceStyle = {};
  const valList = isString(value)
    ? value.split(" ").map(val => convertUnitToSize(val))
    : [value];

  if (valList.length === 1) {
    spaceStyle[`${type}`] = valList[0]; // eslint-disable-line prefer-destructuring
  } else if (valList.length === 2) {
    spaceStyle[`${type}Top`] = valList[0]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Bottom`] = valList[0]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Right`] = valList[1]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Left`] = valList[1]; // eslint-disable-line prefer-destructuring
  } else if (valList.length === 3) {
    spaceStyle[`${type}Top`] = valList[0]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Right`] = valList[1]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Left`] = valList[1]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Bottom`] = valList[2]; // eslint-disable-line prefer-destructuring
  } else if (valList.length === 4) {
    spaceStyle[`${type}Top`] = valList[0]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Right`] = valList[1]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Bottom`] = valList[2]; // eslint-disable-line prefer-destructuring
    spaceStyle[`${type}Left`] = valList[3]; // eslint-disable-line prefer-destructuring
  }

  return spaceStyle;
};

const convertSingleSpacing = key => value => ({
  [key]: convertUnitToSize(value),
});

const convertFontFamily = font => {
  let nativeFont;

  if ((font || "").toLowerCase().indexOf("arial") > -1) {
    nativeFont = "Arial";
  }

  return { fontFamily: nativeFont };
};

const convertSize = key => value => ({
  [key]: convertUnitToSize(value),
});

const formatterMap = {
  flex: strToInt("flex"),
  padding: convertSpacing("padding"),
  paddingLeft: convertSingleSpacing("paddingLeft"),
  paddingRight: convertSingleSpacing("paddingRight"),
  paddingTop: convertSingleSpacing("paddingTop"),
  paddingBottom: convertSingleSpacing("paddingBottom"),
  margin: convertSpacing("margin"),
  marginLeft: convertSingleSpacing("marginLeft"),
  marginRight: convertSingleSpacing("marginRight"),
  marginTop: convertSingleSpacing("marginTop"),
  marginBottom: convertSingleSpacing("marginBottom"),
  minWidth: convertSingleSpacing("minWidth"),
  maxWidth: convertSingleSpacing("maxWidth"),
  minHeight: convertSingleSpacing("minHeight"),
  maxHeight: convertSingleSpacing("maxHeight"),
  fontSize: convertSize("fontSize"),
  fontFamily: convertFontFamily,
  letterSpacing: convertSize("letterSpacing"),
  borderStyle: convertBorderStyle,
  borderWidth: convertSpacing("borderWidth"),
  borderBottomWidth: convertSpacing("borderBottomWidth"),
  borderTopWidth: convertSpacing("borderTopWidth"),
  borderLeftWidth: convertSpacing("borderLeftWidth"),
  borderRightWidth: convertSpacing("borderRightWidth"),
};

const normaliseStyle = style =>
  Object.keys(style).reduce((curObj, key) => {
    const value = style[key];

    if (value !== "") {
      const formatter = formatterMap[key];
      const newStyle = formatter ? formatter(value) : { [key]: value };

      return {
        ...curObj,
        ...newStyle,
      };
    }
    return curObj;
  }, {});

export const extractBaseTextStyle = style =>
  normaliseStyle(pick(style, BASE_TEXT_STYLE_KEYS));
export const extractTextStyle = style =>
  normaliseStyle(pick(style, TEXT_STYLE_KEYS));
export const extractViewStyle = style =>
  normaliseStyle(omit(style, TEXT_ONLY_KEYS));

export const formatTextStyle = (style, parentStyle) => ({
  ...extractBaseTextStyle(parentStyle),
  ...extractTextStyle(style),
});

const referencePathMap = {
  firstName: "name.first",
  middleName: "name.middle",
  lastName: "name.surname",
  street: "address.street",
  suburb: "address.suburb",
  state: "address.state",
  postcode: "address.postcode",
  mobilePhone: "phone.mobile",
  phone: "phone.work",
  fax: "phone.fax",
  companyName: "company.name",
  companyUrl: "company.url",
  email: "email",
  logo: "logo",
  jobTitle: "jobTitle",
};

export const isReferencePhone = reference =>
  ["mobilePhone", "phone", "fax"].indexOf(reference) !== -1;

export const getFieldText = (userInfo, reference) => {
  const fieldPath = referencePathMap[reference];
  const fieldText = get(userInfo, fieldPath, null);

  return fieldText;
};
