import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { CARD_BUILDER, TEMPLATES, PEOPLE, ADMIN, ABOUT_CARD_BUILDER } from "../helpers/paths";
import "../css/cbHeader.css";

const routeInfo = [
  { path: CARD_BUILDER, name: "Home" },
  { path: TEMPLATES, name: "Templates" },
  { path: PEOPLE, name: "People" },
  { path: ADMIN, name: "Admin" },
  { path: ABOUT_CARD_BUILDER, name: "About" },
];

const CBHeader = ({ logout, path }) => (
  <div className="cb_header_container">
    <ul className="cb_header_menu">
      {
        routeInfo.map(({ path: linkPath, name }) =>
          <li key={name} className={(path === linkPath) ? "cb_header_selected" : null}>
            <Link to={linkPath}>{name}</Link>
          </li>
        )
      }
    </ul>
    <Button bsStyle="primary" onClick={logout}>Logout</Button>
  </div>
);

CBHeader.displayName = "CBHeader";
CBHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default CBHeader;
