import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MenuItem } from "./MenuItem";

import "../../css/Pallette.css";

const textItem = {
  type: "text",
  content: "text",
  style: {},
};

const fieldItem = {
  type: "field",
  reference: "firstName",
  style: {},
};

const containerItem = {
  type: "container",
  style: {
    backgroundColor: "yellow",
    display: "flex",
    flex: 1,
    padding: 5,
  },
  children: [],
};

const paragraphItem = {
  type: "paragraph",
  style: {
    backgroundColor: "yellow",
    padding: "1rem",
  },
  children: [],
};

const imageItem = {
  type: "image",
  image: "http://www.realflame.com.au/client/flame/img/logo-1.jpg",
  style: {
    width: 50,
    height: 50,
  },
};

const anchorItem = {
  type: "anchor",
  reference: "companyUrl",
  target: "_blank",
};

const emailItem = {
  type: "email",
  reference: "email",
};

const components = [
  { name: "Container", placeHolder: containerItem },
  { name: "Paragraph", placeHolder: paragraphItem },
  { name: "Field", placeHolder: fieldItem },
  { name: "Text", placeHolder: textItem, },
  { name: "Email", placeHolder: emailItem, },
  { name: "Link", placeHolder: anchorItem, },
  { name: "Image", placeHolder: imageItem },
];

const itemDropHandler = (dropItem, placeHolder) => (parentId) => {
  dropItem({
    parentId,
    item: placeHolder,
  });
};

const Pallette = ({ className, dropItem }) => (
  <ul className={classNames(className, "pallette_container")}>
    {
      components.map(({ name, placeHolder }) => (
        <MenuItem
          key={name}
          name={name}
          onDrop={itemDropHandler(dropItem, placeHolder)}
        />
      ))
    }
  </ul>
);

Pallette.displayName = "Pallette";
Pallette.propTypes = {
  className: PropTypes.string,
  dropItem: PropTypes.func.isRequired,
};

export default Pallette;
