import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";

import {
  SET_PALLETTE_SELECTION,
  SET_SELECTED_ITEM,
  SET_SIDE,
  RESET_SETTINGS,
} from "../actions/editorSettings";

const initialState = Immutable.from({
  palletteSelection: null,
  selectedItem: 0,
  editFront: true,
});

export default handleActions({
  [SET_PALLETTE_SELECTION]: (state, { payload }) => {
    return state.merge({
      palletteSelection: payload,
    });
  },
  [SET_SELECTED_ITEM]: (state, { payload }) => {
    return state.merge({
      selectedItem: payload,
    });
  },
  [SET_SIDE]: (state, { payload }) => {
    return state.merge({
	  editFront: !!payload,
      selectedItem: 0,
      palletteSelection: null,
    });
  },
  [RESET_SETTINGS]: (state) => {
    return state.merge(initialState);
  },
}, initialState);
