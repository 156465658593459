import { handleActions } from "redux-actions";
import Immutable from "seamless-immutable";

import {
  FETCH_USER_CARD,
  FETCH_USER_CARD_ERROR,
  FETCH_USER_CARD_SUCCESS,
} from "../actions/userCardActions";

const initialState = Immutable.from({
  isFetching: false,
  data: null,
  error: null,
});

export default handleActions({
  [FETCH_USER_CARD]: state => state.merge({
    isFetching: true,
    data: null,
    error: null,
  }),
  [FETCH_USER_CARD_SUCCESS]: (state, { payload }) => state.merge({
    isFetching: false,
    data: payload,
    error: null,
  }),
  [FETCH_USER_CARD_ERROR]: (state, { payload }) => state.merge({
    isFetching: false,
    data: null,
    error: payload,
  }),
}, initialState);
