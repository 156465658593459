import { createSelector } from "reselect";
import { isEditorSideFront, getSelectedItem } from "./editorSettings";
import { getData } from "./cardEditor";

const getCardSide = createSelector(
  isEditorSideFront,
  getData,
  (isFront, card) => {
    let cardSide = null;

    if (card) {
      cardSide = isFront ? card.front : card.back;
    }

    return cardSide;
  }
);

const matchChild = (child, id) => {
  if (child.id === id) {
    return child;
  } else if (child.children && child.children.length > 0) {
    let result = null;
	for (var i=0; !result && i < child.children.length; ++i) {
      result = matchChild(child.children[i], id);
    }
    return result;
  }

  return null;
};

const getSelectedComponent = createSelector(
  getSelectedItem,
  getCardSide,
  (selectedId, cardSide) => {
    // Find the matching component
    let selectedComponent = null;

    if (selectedId > 0) {
      selectedComponent = matchChild(cardSide, selectedId);
    }

    return selectedComponent;
  }
);
export {
  getCardSide,
  getSelectedComponent,
};
