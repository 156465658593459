import React from "react";
import PropTypes from "prop-types";
import { merge, clone } from "lodash";

import { extractTextStyle } from "./utils";
import CardComponent from "./CardComponent";
import Container from "./Container";

const BASE_CONTAINER_STYLE = {
  flex: 1,
};

const JsonCard = ({ userInfo, template }) => {
  const baseTextStyle = extractTextStyle(merge(clone({}), template.style));
  const containerStyle = {
    ...BASE_CONTAINER_STYLE,
    ...template.style,
  };

  return (
    <Container style={containerStyle}>
      {(template.children || []).map(child => (
        <CardComponent
          key={child.id}
          content={child}
          userInfo={userInfo}
          baseTextStyle={baseTextStyle}
        />
      ))}
    </Container>
  );
};

JsonCard.displayName = "JsonCard";
JsonCard.propTypes = {
  userInfo: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};

export default JsonCard;
