import React from "react";
import PropTypes from "prop-types";
import {
  Text,
  Linking,
  TouchableOpacity,
  StyleSheet,
  View,
} from "react-native";

import { LINK_COLOR } from "../utils/styleUtils";

export const styles = StyleSheet.create({
  link: {
    color: LINK_COLOR,
    textDecorationLine: "underline",
  },
});

const openLink = url => () => {
  Linking.openURL(url).catch(err => console.log(`Error opening url: ${err}`)); // eslint-disable-line no-console
};

export const WebEmailLink = ({
  webAddress,
  text,
  textStyle,
  style,
  ...otherProps
}) => (
  <TouchableOpacity
    onPress={webAddress ? openLink(webAddress) : undefined}
    {...otherProps}
  >
    <View style={style}>
      <Text style={[styles.link, textStyle]}>{text}</Text>
    </View>
  </TouchableOpacity>
);

WebEmailLink.propTypes = {
  webAddress: PropTypes.string,
  text: PropTypes.string.isRequired,
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export const EmailLink = ({
  email,
  textStyle,
  style,
  displayEmail,
  readOnly,
  ...otherProps
}) => (
  <WebEmailLink
    webAddress={readOnly ? undefined : `mailto:${email}`}
    text={displayEmail || email}
    style={style}
    textStyle={textStyle || {}}
    {...otherProps}
  />
);

EmailLink.propTypes = {
  email: PropTypes.string.isRequired,
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  displayEmail: PropTypes.string,
  readOnly: PropTypes.bool,
};

export const WebLink = ({
  url,
  textStyle,
  style,
  readOnly,
  displayUrl,
  ...otherProps
}) => (
  <WebEmailLink
    webAddress={readOnly ? undefined : url}
    text={displayUrl || url}
    style={style}
    textStyle={textStyle}
    {...otherProps}
  />
);

WebLink.propTypes = {
  url: PropTypes.string.isRequired,
  textStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  displayUrl: PropTypes.string,
  readOnly: PropTypes.bool,
};
