import { createSelector } from "reselect";

const getUserAccount = state => state.userAccount;

export const getAccountId = createSelector(
  getUserAccount,
  userAccount => userAccount.accountId
);

export const getAuthorization = createSelector(
  getUserAccount,
  userAccount => userAccount.accessToken ? `Bearer ${userAccount.accessToken}` : ""
);

export const getAccountInfo = createSelector(
  getUserAccount,
  userAccount => userAccount.accountInfo
);

export const getIsLoggedIn = createSelector(
  getUserAccount,
  userAccount => Boolean(userAccount.accessToken && userAccount.accountId && userAccount.accountInfo)
);

export const getOrgId = createSelector(
  getAccountInfo,
  accountInfo => accountInfo && accountInfo.orgId
);
