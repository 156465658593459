import { createSelector } from "reselect";

const getEditorSettings = state => state.editorSettings;

const getPalletteSelection = createSelector(
  getEditorSettings,
  settings => settings.palletteSelection
);

const getSelectedItem = createSelector(
  getEditorSettings,
  settings => settings.selectedItem
);

const isEditorSideFront = createSelector(
  getEditorSettings,
  settings => settings.editFront
);

export {
  getPalletteSelection,
  getSelectedItem,
  isEditorSideFront,
};
