import { takeEvery, select, put } from "redux-saga/effects";
import { ADD_ITEM } from "../actions/cardEditor";
import { getLatestId } from "../selectors/cardEditor";
import { setSelectedItem } from "../actions/editorSettings";

export function *watchAddItem() {
  console.log('Got add item...................');
  const latestId = yield select(getLatestId);
  console.log(`Latest ID: ${latestId}`);
  // Set selected item to this id
  yield put(setSelectedItem(latestId));
}

export default function *editorWatcher() {
  yield takeEvery(ADD_ITEM, watchAddItem);
}
