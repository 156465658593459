import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { ConnectedRouter as Router, routerMiddleware } from "connected-react-router";
import { Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Route } from "react-router";
import createSagaMiddleware from "redux-saga";
import Amplify from "aws-amplify";
import awsExports from "./config/aws-exports";
import createRootReducer from "./reducers";
import sagas from "./sagas";
import { setupIcons } from "./helpers/setupIcons";
import AppHome from "./pages/AppHome";
import Login from "./containers/loginPage";
import DisplayCard from "./pages/DisplayCardPage";
import CardBuilderPage from "./pages/CardBuilder/CardBuilderPage";

import { CARD_BUILDER } from "./helpers/paths";

import "./App.css";

const isDevelopment = () => process.env.NODE_ENV === "development";

const composeEnhancers = (isDevelopment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

Amplify.configure(awsExports);

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
];

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middleware))
);

// Run the sagas
sagaMiddleware.run(sagas);

setupIcons();

const NoMatch = () => <div>No Match</div>;

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/" exact component={AppHome} />
          <Route path={CARD_BUILDER} component={CardBuilderPage} />
          <Route path="/card/:id" component={DisplayCard} />
          <Route path="/login" component={Login} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    </Router>
  </Provider>
);

export default App;
