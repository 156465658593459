import React from "react";
import PropTypes from "prop-types";
import { pick, omit } from "lodash";

import { WebLink } from "../../../components/WebEmailLink";

import {
  getFieldText,
  formatTextStyle,
  EXCLUDE_WEB_EMAIL_STYLE_PROPS,
} from "./utils";
import { textPropTypes, defaultTextProps } from "./propTypes";

const VALID_ANCHOR_PROPS = ["onClick", "readOnly"];

const Anchor = ({
  style,
  reference,
  userInfo,
  baseTextStyle,
  content,
  ...otherProps
}) => {
  const webAddress = getFieldText(userInfo, reference);

  return webAddress ? (
    <WebLink
      url={webAddress}
      textStyle={formatTextStyle(
        style,
        omit(baseTextStyle, EXCLUDE_WEB_EMAIL_STYLE_PROPS),
      )}
      displayUrl={content}
      {...pick(otherProps, VALID_ANCHOR_PROPS)}
    />
  ) : null;
};
Anchor.propTypes = {
  ...textPropTypes,
  reference: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
};
Anchor.defaultProps = {
  ...defaultTextProps,
};

export default Anchor;
