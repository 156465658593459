import { createAction } from "redux-actions";

import { getAccountId } from "../selectors/userAccount";
import { getData } from "../selectors/cardEditor";

export const FETCH_CARD = "CARD_EDITOR/FETCH_CARD";
export const FETCH_CARD_ERROR = "CARD_EDITOR/FETCH_CARD_ERROR";
export const FETCH_CARD_SUCCESS = "CARD_EDITOR/FETCH_CARD_SUCCESS";
export const SAVE_CARD = "CARD_EDITOR/SAVE_CARD";
export const SAVE_CARD_ERROR = "CARD_EDITOR/SAVE_CARD_ERROR";
export const SAVE_CARD_SUCCESS = "CARD_EDITOR/SAVE_CARD_SUCCESS";
export const SET_CARD = "CARD_EDITOR/SET_CARD";
export const INIT_NEW_CARD = "CARD_EDITOR/INIT_NEW_CARD";

export const SET_FIELD_TYPE = "CARD_EDITOR/SET_FIELD_TYPE";
export const SET_TEXT_CONTENT = "CARD_EDITOR/SET_TEXT_CONTENT";
export const SET_STYLE = "CARD_EDITOR/SET_STYLE";
export const SET_IMAGE = "CARD_EDITOR/SET_IMAGE";
export const SET_BACKGROUND_IMAGE = "CARD_EDITOR/SET_BACKGROUND_IMAGE";
export const SET_LINEAR_GRADIENT = "CARD_EDITOR/SET_LINEAR_GRADIENT";
export const SET_NAME = "CARD_EDITOR/SET_NAME";

export const ADD_ITEM = "CARD_EDITOR/ADD_ITEM";
export const DELETE_ITEM = "CARD_EDITOR/DELETE_ITEM";

export const fetchCardError = createAction(FETCH_CARD_ERROR);
export const fetchCardSuccess = createAction(FETCH_CARD_SUCCESS);
export const fetchCard = createAction(
  FETCH_CARD,
  null,
  (cardId) => ({
    method: "GET",
    view: "cardEditor",
    path: `template/${cardId}`,
    params: {
      accountId: getAccountId,
    },
    needsAuthorization: true,
    onSuccess: fetchCardSuccess,
    onError: fetchCardError,
  }),
);
export const saveCardError = createAction(SAVE_CARD_ERROR);
export const saveCardSuccess = createAction(SAVE_CARD_SUCCESS);
export const saveCard = createAction(
  SAVE_CARD,
  null,
  ({ card, isNew }) => ({
    method: isNew ? "POST" : "PUT",
    view: "cardEditor",
    path: `template/${card.id}`,
    params: {
      accountId: getAccountId,
    },
    needsAuthorization: true,
    body: {
      card: getData,
    },
    onSuccess: saveCardSuccess,
    onError: saveCardError,
  }),
);

export const setCard = createAction(SET_CARD);
export const initNewCard = createAction(INIT_NEW_CARD);

export const setFieldType = createAction(SET_FIELD_TYPE);
export const setTextContent = createAction(SET_TEXT_CONTENT);
export const setStyle = createAction(SET_STYLE);
export const setImage = createAction(SET_IMAGE);
export const setBackgroundImage = createAction(SET_BACKGROUND_IMAGE);
export const setLinearGradient = createAction(SET_LINEAR_GRADIENT);
export const setName = createAction(SET_NAME);

export const addItem = createAction(ADD_ITEM);
export const deleteItem = createAction(DELETE_ITEM);
