import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DndProvider } from "react-dnd/dist/esm";
import HTML5Backend from "react-dnd-html5-backend";

import { cardPropType } from "../../types/card";
import { errorPropType } from "../../types/error";
import { TEMPLATES } from "../../helpers/paths";
import CardSummary from "./CardSummary";
import Pallette from "../../containers/pallette";
import WireFrame from "./WireFrame";
import ComponentTree from "./ComponentTree";
import FieldEditor from "../../containers/fieldEditor";

import "../../css/CardEditor.css";

const CardEditorContent = (props) => (
  <DndProvider backend={HTML5Backend}>
    <div className="card_editor_container">
      <CardSummary {...props} />
      <div className="card_editor_main">
        <Pallette className="card_editor_child card_editor_sidebar" />
        <WireFrame className="card_editor_child" />
        <ComponentTree className="card_editor_child" />
        <FieldEditor className="card_editor_child card_editor_editor" />
      </div>
    </div>
  </DndProvider>
);

CardEditorContent.displayName = "CardEditorContent";
CardEditorContent.propTypes = {
  card: cardPropType.isRequired,
  saveCard: PropTypes.func.isRequired,
  setCardData: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

const CardEditor = (props) => {
  const { match, card, error, fetch, isNew } = props;
  useEffect(() => {
    !isNew && fetch(match.params.id);
  }, [fetch, isNew, match]);

  let content;

  if (error) {
    content = <div><h3>Got error</h3></div>;
  } else if (card) {
    content = <CardEditorContent {...props} />;
  } else {
    content = <div><h3>Fetching..</h3></div>;
  }

  return (
    <div className="card_editor_main_container">
      <h3>Card Editor</h3>
      <p>{`Card ID: ${match.params.id}`}</p>
      {content}
      <Link to={TEMPLATES}>Back to cards</Link>
    </div>
  );
};

CardEditor.displayName = "CardEditor";
CardEditor.propTypes = {
  match: PropTypes.object.isRequired,
  card: cardPropType,
  error: errorPropType,
  isNew: PropTypes.bool.isRequired,
};

export default CardEditor;
