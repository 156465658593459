import PropTypes from "prop-types";

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.element),
]);

export const contentPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

export const textPropTypes = {
  style: PropTypes.object,
  baseTextStyle: PropTypes.object,
};

export const defaultTextProps = {
  style: {},
  baseTextStyle: {},
};
