import React, { useState } from "react";
import PropTypes from "prop-types";
import { clone } from "lodash";
import classNames from "classnames"

import { useNewComponentDrop } from "./dragDropConstants";
import { cardComponentPropType } from "../../types/cardComponent";
import { extractTextStyle } from "../../app/Vbiz/vbiz/shared/components/JsonCard/utils";
import Container from "../../app/Vbiz/vbiz/shared/components/JsonCard/Container";
import Paragraph from "../../app/Vbiz/vbiz/shared/components/JsonCard/Paragraph";
import Field from "../../app/Vbiz/vbiz/shared/components/JsonCard/Field";
import TextComponent from "../../app/Vbiz/vbiz/shared/components/JsonCard/TextComponent";
import Anchor from "../../app/Vbiz/vbiz/shared/components/JsonCard/Anchor";
import Email from "../../app/Vbiz/vbiz/shared/components/JsonCard/Email";
import ImageComponent from "../../app/Vbiz/vbiz/shared/components/JsonCard/ImageComponent";

import "../../css/WireFrameComponent.css";

const typeMap = {
  container: Container,
  field: Field,
  paragraph: Paragraph,
  text: TextComponent,
  anchor: Anchor,
  email: Email,
  image: ImageComponent,
};

const handleClick = ({ setSelectedItem, id }) => (e) => {
  e.preventDefault();
  // Don't bubble to parent
  e.stopPropagation();

  setSelectedItem(id);
};

const handleHighlight = (setHighlighted, highlight) => (e) => {
  e.stopPropagation();
  setHighlighted(highlight);
};

const WireFrameComponent = ({
  setSelectedItem,
  selectedItem,
  userInfo,
  content: {
    id,
    children,
    type,
    style,
    ...otherContentProps
  },
  baseTextStyle,
}) => {
  const [{ isOver }, drop] = useNewComponentDrop(id, type);
  const [isHighlighted, setHighlighted] = useState(false);

  const Wrapper = typeMap[type] || Container;
  const newBaseTextStyle = {
    ...baseTextStyle,
    ...extractTextStyle(style || {}),
  };

  return (
    <div
      ref={drop}
      className={
        classNames(
          "wire_frame_component_container",
          {
            "wire_frame_component_highlighted": isHighlighted,
            "wire_frame_component_selected": (id === selectedItem),
            "wire_frame_component_dropping": isOver,
          },
        )
      }
      onClick={handleClick({ setSelectedItem, id })}
      onMouseOver={handleHighlight(setHighlighted, true)}
      onMouseOut={handleHighlight(setHighlighted, false)}
    >
      <Wrapper
        userInfo={userInfo}
        style={style}
        readOnly
        baseTextStyle={clone(newBaseTextStyle)}
        {...otherContentProps}
      >
        {
          (children || []).map(child => {
            return (
              <WireFrameComponent
                content={child}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                key={Math.random()}
                userInfo={userInfo}
                baseTextStyle={newBaseTextStyle}
              />
            );
          })
        }
      </Wrapper>
    </div>
  );
}

WireFrameComponent.displayName = "WireFrameComponent";
WireFrameComponent.propTypes = {
  content: cardComponentPropType.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default WireFrameComponent;
