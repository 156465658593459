import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { WireFrameHolder } from "./WireFrameHolder";

import { cardPickerContainer } from "../../containers/cardPickerContainer";

import { cardSidePropType } from "../../types/cardSide";

export const WireFrame = ({
  className,
  cardSide,
  setSelectedItem,
  selectedItem,
}) => {

  if (!cardSide) {
    // TODO: Spinner component
    return (
      <div className={classNames(className, "wire_frame_container")} >
        Loading...
      </div>
    );
  }

  const { htmlOnly } = cardSide;

  if (htmlOnly) {
    // TODO: Do something here or get rid of this option
  }

  return (
    <WireFrameHolder
      cardSide={cardSide}
      setSelectedItem={setSelectedItem}
      className={className}
      selectedItem={selectedItem}
    />
  );
};

WireFrame.displayName = "WireFrame";
WireFrame.propTypes = {
  className: PropTypes.string,
  selectedItem: PropTypes.number,
  cardSide: cardSidePropType,
  setSelectedItem: PropTypes.func.isRequired,
};

export default cardPickerContainer(WireFrame);
