import PropTypes from "prop-types";
import Immutable from "seamless-immutable";

import Person from "./person";

class PeopleList {
  constructor({
    people,
  }) {
    return Immutable.from({
      people: people.map(person => {
        return new Person(person);
      }),
	}, { prototype: this.constructor.prototype });
  }
}

const peopleListPropType = PropTypes.instanceOf(PeopleList);

export {
  PeopleList as default,
  peopleListPropType,
};
