export const signUpConfig = {
  hiddenDefaults: ["phone_number"],
  signUpFields: [
    {
      label: "First Name",
      key: "given_name",
      required: true,
      displayOrder: 1,
      type: "string",
    },
    {
      label: "Surname",
      key: "family_name",
      required: true,
      displayOrder: 2,
      type: "string",
    },
    {
      label: "Username",
      key: "username",
      required: true,
      displayOrder: 3,
      type: "string",
    },
    {
      label: "Email",
      key: "email",
      required: true,
      displayOrder: 4,
      type: "email",
    },
    {
      label: "Password",
      key: "password",
      required: true,
      displayOrder: 5,
      type: "password",
    },
  ],
};
