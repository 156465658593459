import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import "../../css/CardEditor.css";

const LinearGradientDetails = ({ colors, horizontal, onChange }) => {
  return (
    <Fragment>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Vertical</ControlLabel>
        <FormControl
          type="radio"
          checked={!horizontal}
          onChange={() => onChange({ horizontal: false, colors })}
        />
        <ControlLabel>Horizontal</ControlLabel>
        <FormControl
          type="radio"
          checked={horizontal}
          onChange={() => onChange({ horizontal: true, colors })}
        />
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Colors</ControlLabel>
        <FormControl
          type="input"
          value={colors}
          onChange={e => {
            onChange({ horizontal, colors: e.target.value })
          }}
        />
      </FormGroup>
    </Fragment>
  );
};

LinearGradientDetails.propTypes = {
  colors: PropTypes.string,
  horizontal: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

LinearGradientDetails.defaultProps = {
  colors: "",
  horizontal: false,
};

export default LinearGradientDetails;
