import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";

import BackgroundImageDetails from "./BackgroundImageDetails";
import ImageDetails from "./ImageDetails";
import { cardComponentPropType } from "../../types/cardComponent";

const refMap = {
  "firstName": "First Name",
  "lastName": "Last Name",
  "middleName": "Middle Name",
  "companyName": "Company Name",
  "phone": "Phone Number",
  "mobilePhone": "Phone (Mob)",
  "fax": "Fax",
  "street": "Street",
  "suburb": "Suburb",
  "state": "State",
  "country": "Country",
  "postcode": "Post Code",
  "jobTitle": "Job Title",
};

const fontOptions = [
  "inherit",
  "Tahoma, Geneva, sans-serif",
  "Arial, Helvetica, sans-serif",
  "cursive",
];

const borderOptions = [
  "solid",
  "dotted",
  "dashed",
];

const directionOptions = [ "row", "column", "row-reverse", "columnn-reverse" ];
const alignOptions = [
  "stretch",
  "flex-start",
  "flex-end",
  "center",
  "baseline",
];
const justifyOptions = [
  "flex-start",
  "flex-end",
  "center",
  "space-between",
  "space-around",
];
const displayOptions = [
  "flex",
  "block",
  "inline",
  "inline-block",
];
const fontWeightOptions = ["normal", "bold", "bolder", "lighter" ];
const textTransformOptions = ["none", "capitalize", "uppercase", "lowercase"];

const TextStyleDetails = ({
  cardSide,
  selectedComponent,
  setStyle,
}) => {
  const style = (selectedComponent || cardSide).style || {};

  return (
    <Fragment>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Text Color</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.color}
          onChange={e => {
            setStyle({ color: e.target.value });
          }}
        />
      </FormGroup>
      <h3>Font</h3>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Family</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.fontFamily || fontOptions[0]}
          onChange={e => setStyle({ fontFamily: e.target.value })}
        >
          {fontOptions.map(font => <option value={font} key={font}>{font}</option>)}
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Weight</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.fontWeight || fontWeightOptions[0]}
          onChange={e => setStyle({ fontWeight: e.target.value })}
        >
          {fontWeightOptions.map(weight => <option value={weight} key={weight}>{weight}</option>) }
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Size</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.fontSize || ""}
          onChange={e => {
            setStyle({ fontSize: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Text Transform</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.textTransform || textTransformOptions[0]}
          onChange={e => setStyle({ textTransform: e.target.value })}
        >
          {textTransformOptions.map(t => <option value={t} key={t}>{t}</option>)}
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Spacing</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.letterSpacing}
          onChange={e => setStyle({ letterSpacing: e.target.value })}
        />
      </FormGroup>
    </Fragment>
  );
};
TextStyleDetails.propTypes = {
  selectedComponent: cardComponentPropType,
  setStyle: PropTypes.func.isRequired,
};

const FlexDisplayDetails = ({
  componentStyle: style,
  setStyle,
}) => {
  return (
    <Fragment>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Direction</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.flexDirection || directionOptions[0]}
          onChange={e => setStyle({ flexDirection: e.target.value })}
        >
          {
            directionOptions.map(val => <option value={val} key={val}>{val}</option>)
          }
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Align Items</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.alignItems || alignOptions[0]}
          onChange={e => setStyle({ alignItems: e.target.value })}
        >
          {
            alignOptions.map(val => <option value={val} key={val}>{val}</option>)
          }
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Justify Content</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.justifyContent || justifyOptions[0]}
          onChange={e => setStyle({ justifyContent: e.target.value })}
        >
          {
            justifyOptions.map(val => <option value={val} key={val}>{val}</option>)
          }
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Flex</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.flex || ""}
          onChange={e => {
            setStyle({ flex: e.target.value });
          }}
        />
      </FormGroup>
    </Fragment>
  );
};

const SIZE_DETAIL_ITEMS = [
  { label: "Min Width", key: 'minWidth' },
  { label: "Max Width", key: 'maxWidth' },
  { label: "Min Height", key: 'minHeight' },
  { label: "Max Height", key: 'maxHeight' },
];

const SizeDetails = ({
  componentStyle: style,
  setStyle,
}) => (
  <Fragment>
    {
      SIZE_DETAIL_ITEMS.map(({ label, key }) => (
        <FormGroup key={key} className="card_editor_inline_group">
          <ControlLabel>{label}</ControlLabel>
          <FormControl
            className="card_editor_value"
            type="input"
            value={style[key] || ""}
            onChange={e => setStyle({ [key]: e.target.value })}
          />
        </FormGroup>
      ))
    }
  </Fragment>
);

// TODO: Put this in it's own component
// Local state to determine image or test
// If image the prop backgroundImage exists somewhere
/*
const BackgroundImageDetails = ({
  selectedComponent,
  setBackgroundImage,
  setStyle,
  style,
}) => {
  console.log('background image', selectedComponent);
  const content = (
    <ImageDetails
      selectedComponent={selectedComponent}
      setImage={setBackgroundImage}
      setStyle={setStyle}
      hideDimensions
    />
  );

  return (
    <Fragment>
      {content}
    </Fragment>
  );
};
*/

const ContainerDetails = ({
  cardSide,
  selectedComponent,
  setStyle,
  setBackgroundImage,
  setLinearGradient,
}) => {
  const style = (selectedComponent || cardSide).style || {};
  const borderWidthVal = (style.borderWidth && style.borderWidth.slice(0,-2)) || 0;

  return (
    <Form inline>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Display</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.display || displayOptions[0]}
          onChange={e => setStyle({ display: e.target.value })}
        >
          {
            displayOptions.map(val => <option value={val} key={val}>{val}</option>)
          }
        </FormControl>
      </FormGroup>
      {
        (style.display === "flex") && <FlexDisplayDetails componentStyle={style} setStyle={setStyle} />
      }
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Background</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.backgroundColor}
          onChange={e => setStyle({ backgroundColor: e.target.value })}
        />
      </FormGroup>
      <TextStyleDetails setStyle={setStyle} selectedComponent={selectedComponent} cardSide={cardSide} />
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Padding</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.padding || ""}
          onChange={e => setStyle({ padding: e.target.value })}
        />
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Margin</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={style.margin || ""}
          onChange={e => setStyle({ margin: e.target.value })}
        />
      </FormGroup>
      <h3>Border</h3>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Border Style</ControlLabel>
        <FormControl
          className="card_editor_value"
          componentClass="select"
          value={style.borderStyle || borderOptions[0]}
          onChange={e => setStyle({ borderStyle: e.target.value })}
        >
          {
            borderOptions.map(border => <option value={border} key={border}>{border}</option>)
          }
        </FormControl>
      </FormGroup>
      <FormGroup className="card_editor_inline_group">
        <ControlLabel>Width</ControlLabel>
        <FormControl
          className="card_editor_value"
          type="input"
          value={borderWidthVal}
          onChange={e => {
            setStyle({ borderWidth: `${e.target.value}px` });
		  }}
        />
      </FormGroup>
      <SizeDetails componentStyle={style} setStyle={setStyle} />
      <h3>Background Image</h3>
      <BackgroundImageDetails
        selectedComponent={selectedComponent}
        setBackgroundImage={setBackgroundImage}
        setStyle={setStyle}
        style={style}
        setLinearGradient={setLinearGradient}
      />
    </Form>
  );
};
ContainerDetails.displayName = "ContainerDetails";
ContainerDetails.propTypes = {
  selectedComponent: cardComponentPropType,
  setStyle: PropTypes.func.isRequired,
  setBackgroundImage: PropTypes.func.isRequired,
  setLinearGradient: PropTypes.func.isRequired,
};

const FieldDetails = ({
  selectedComponent,
  selectedComponent: {
    reference
  },
  setFieldType,
  disabled,
  ...otherProps
}) => (
  <Form inline>
    <FormGroup className="card_editor_inline_group">
      <ControlLabel>Data</ControlLabel>
      <FormControl
        className="card_editor_value"
        componentClass="select"
        value={reference}
        onChange={e => setFieldType(e.target.value)}
        disabled={!!disabled}
      >
        {
          Object.keys(refMap).map(key => <option value={key} key={key}>{refMap[key]}</option>)
        }
      </FormControl>
    </FormGroup>
    <TextStyleDetails selectedComponent={selectedComponent} {...otherProps} />
  </Form>
);

const EmailDetails = ({ ...props }) => <FieldDetails disabled {...props} />;

const TextDetails = ({
  selectedComponent,
  selectedComponent: {
    id,
    content,
    style,
  },
  setText,
  setStyle,
  ...otherProps
}) => (
  <Form inline>
    <FormGroup>
      <ControlLabel>Text</ControlLabel>
      <FormControl type="text" value={content} onChange={e => setText(e.target.value)} />
    </FormGroup>
    <FormGroup>
      <ControlLabel>Min Width</ControlLabel>
      <FormControl
        type="input"
        value={style.minWidth || ""}
        onChange={e => setStyle({ minWidth: e.target.value })}
      />
    </FormGroup>
    <TextStyleDetails setStyle={setStyle} selectedComponent={selectedComponent} {...otherProps} />
  </Form>
);
TextDetails.displayName = "TextDetails";
TextDetails.propTypes = {
  selectedComponent: cardComponentPropType,
  setText: PropTypes.func.isRequired,
  setStyle: PropTypes.func.isRequired,
};

const Anchor = ({
  selectedComponent,
  selectedComponent: {
    id,
    reference,
    target,
    content,
  },
  setText,
  setStyle,
  ...otherProps
}) => (
  <Form inline>
    <FormGroup className="card_editor_inline_group">
      <ControlLabel>Display</ControlLabel>
      <FormControl
        className="card_editor_value"
        type="text"
        value={content}
        onChange={
          e => setText(e.target.value)
        }
      />
    </FormGroup>
    <TextStyleDetails setStyle={setStyle} selectedComponent={selectedComponent} {...otherProps} />
    <div>{`ID: ${id}`}</div>
    <div>{`Ref: ${reference}`}</div>
    <div>{`Target: ${target}`}</div>
  </Form>
);

const Paragraph = ({
  selectedComponent,
  setText,
  setStyle,
  setBackgroundImage,
  setLinearGradient,
}) => {
  let content;
  if (selectedComponent.content) {
    content = <TextDetails selectedComponent={selectedComponent} setText={setText} setStyle={setStyle} />;
  } else {
    content = <h3>Paragraph</h3>;
  }

  return (
    <Form inline>
      {content}
      <ContainerDetails
        selectedComponent={selectedComponent}
        setStyle={setStyle}
        setBackgroundImage={setBackgroundImage}
        setLinearGradient={setLinearGradient}
      />
    </Form>
  );
};
Paragraph.displayName = "Paragraph";
Paragraph.propTypes = {
  selectedComponent: cardComponentPropType,
  setText: PropTypes.func.isRequired,
  setStyle: PropTypes.func.isRequired,
  setLinearGradient: PropTypes.func.isRequired,
};

const ImageDetailsForm = (props) => (
  <Form inline>
    <ImageDetails {...props} />
  </Form>
);

const typeMap = {
  field: FieldDetails,
  paragraph: Paragraph,
  text: TextDetails,
  anchor: Anchor,
  email: EmailDetails,
  container: ContainerDetails,
  image: ImageDetailsForm,
};

class FieldEditor extends Component {
  static displayName = "FieldEditor";
  static propTypes = {
    className: PropTypes.string,
    selectedItem: PropTypes.number.isRequired,
    selectedComponent: cardComponentPropType,
    setFieldType: PropTypes.func.isRequired,
    setBackgroundImage: PropTypes.func.isRequired,
    setLinearGradient: PropTypes.func.isRequired,
  };

  render() {
    const {
      className,
      selectedItem,
      selectedComponent,
    } = this.props;
    let Wrapper;

    if (selectedItem > 0) {
      Wrapper = typeMap[selectedComponent.type] || FieldDetails;
    } else {
      Wrapper = ContainerDetails;
    }

    return (
      <div className={className}>
        <Wrapper {...this.props} />
      </div>
    );
  }
}

export default FieldEditor;
