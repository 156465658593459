import { takeEvery, put } from "redux-saga/effects";
import { SET_LOGGED_IN, fetchUserAccount } from "../actions/userAccount";

export function *loggedInSaga({ payload }) {
  console.log("In logged in saga", payload);

  yield put(fetchUserAccount(payload.accountId))
}

export default function *loginWatcher() {
  yield takeEvery(SET_LOGGED_IN, loggedInSaga)
}
