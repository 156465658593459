import { connect } from "react-redux";
import { Auth } from "aws-amplify";

import { getIsLoggedIn } from "../selectors/userAccount";
import { logOut } from "../actions/userAccount";
import { getRoutePath } from "../selectors/router";

import CBHeader from "../components/CBHeader";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isLoggedIn: getIsLoggedIn(state),
  path: getRoutePath(state),
});

const mapDispatchToProps = dispatch => ({
  logout: () => {
    Auth.signOut()
    dispatch(logOut());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CBHeader);

