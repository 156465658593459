import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-native";
import { pick } from "lodash";

import { getFieldText, extractViewStyle } from "./utils";

const VALID_IMAGE_PROPS = ["onClick"];

const ImageComponent = ({
  style,
  image,
  reference,
  userInfo,
  ...otherProps
}) => {
  const source = {};

  if (reference) {
    const imageText = getFieldText(userInfo, reference) || "";

    if (imageText.length > 0) {
      source.uri = getFieldText(userInfo, reference) || "";
    }
  } else if (image) {
    source.uri = image.base64 || image;
  }

  const imageStyle = {
    width: "100%",
    height: "100%",
    ...extractViewStyle(style),
  };

  return (
    <Image
      style={imageStyle}
      source={source}
      resizeMode="contain"
      {...pick(otherProps, VALID_IMAGE_PROPS)}
    />
  );
};
ImageComponent.propTypes = {
  style: PropTypes.object,
  image: PropTypes.object,
  reference: PropTypes.string,
  userInfo: PropTypes.object.isRequired,
};
ImageComponent.defaultProps = {
  style: {},
};

export default ImageComponent;
