import React from "react";
import PropTypes from "prop-types";
import JsonCard from "../app/Vbiz/vbiz/shared/components/JsonCard/JsonCard";
import UserInfoComp from "../app/Vbiz/vbiz/components/UserInfo";
import UserInfo from "../app/Vbiz/vbiz/types/userInfo";
import "../css/DisplayCard.css";

const DisplayCard = ({ template, userInfo }) => (
  <div className="display_card_container">
    <div className="display_card_card_holder">
      <JsonCard userInfo={userInfo} template={template} />
    </div>
    <div className="display_card_userinfo">
      <UserInfoComp userInfo={new UserInfo(userInfo)} />
    </div>
  </div>
);

DisplayCard.displayName = "DisplayName";
DisplayCard.propTypes = {
  template: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
};

export default DisplayCard;
