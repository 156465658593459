import { Dimensions } from "react-native";

// Colours
export const PRIMARY_COLOR = "white";
export const SECONDARY_COLOR = "#309C6F";
export const TERTIARY_COLOR = "#BEAF3E";
export const COLOR_4 = "#CE5B46";
export const COLOR_5 = "#C54740";
export const PRIMARY_COLOR_DISABLED = "#FFFFFF80";

export const PRIMARY_TEXT_COLOR = "#0a0a0a";

export const HEADER_COLOR = "#00b33c";
export const HEADER_TEXT_COLOR = PRIMARY_COLOR;

export const MENU_COLOR = PRIMARY_COLOR;
export const MENU_TEXT_COLOR = "#777777";
export const MENU_SELECTED_COLOR = HEADER_COLOR;

export const PRIMARY_BUTTON_COLOR = HEADER_COLOR;
export const PRIMARY_BUTTON_TEXT_COLOR = HEADER_TEXT_COLOR;

export const SECONDARY_BUTTON_COLOR = "#a39ba8";
export const SECONDARY_BUTTON_TEXT_COLOR = HEADER_TEXT_COLOR;

export const LINK_COLOR = "#007aff";
export const ERROR_COLOR = "red";
export const WARNING_COLOR = "red";

export const DISABLED_TEXT_COLOR = "#a39ba8";

export const CARD_BORDER_COLOR = "grey";

export const SELECTED_COLOR = "red";

export const SIGNED_OUT_PALETTE = {
  primaryColor: HEADER_COLOR,
  primaryTextColor: PRIMARY_COLOR,
  primaryButtonColor: PRIMARY_COLOR,
  primaryButtonColorDisabled: PRIMARY_COLOR_DISABLED,
  primaryButtonTextColor: HEADER_COLOR,
  secondaryButtonColor: SECONDARY_BUTTON_COLOR,
  secondaryButtonTextColor: SECONDARY_BUTTON_TEXT_COLOR,
};

export const headerStyle = {
  headerStyle: {
    backgroundColor: HEADER_COLOR,
    borderBottomWidth: 0,
  },
  headerTitleStyle: {
    color: HEADER_TEXT_COLOR,
  },
  headerTintColor: HEADER_TEXT_COLOR,
};

// TODO: Make this based on the app dimensions
export const ICON_SIZE = 24;
const HEADER_HEIGHT = 24;
const PADDING_DEF = 8;
const MENU_BUTTON_SIZE = 24;
const TEXT_LINE_HEIGHT = 24;
const TOOLBAR_HEIGHT = 28;
export const CONTACT_LOGO_SIZE = 48;
export const HEADER_FONT_SIZE = 20;
export const PRIMARY_BUTTON_WIDTH = 100;

const getAppDimensions = () => Dimensions.get("window");

export const getMenuIconSize = () => ICON_SIZE - 2;

// TODO: Update these based on device information
const getHeaderHeight = () => HEADER_HEIGHT;

const getDefaultPadding = () => PADDING_DEF;

const getTextLineHeight = () => TEXT_LINE_HEIGHT;

const getToolbarHeight = () => TOOLBAR_HEIGHT;

export const CARD_SIDE_RATIO = 4 / 6;

// Card ratio width:height = 6:4
export const getCardWidth = (appHeight, appWidth) => {
  let width = appWidth;

  if (!appHeight || !appWidth) {
    const appDimensions = getAppDimensions();

    width = appDimensions.width; // eslint-disable-line prefer-destructuring
  }

  return 0.9 * width;
};

export const getAppHeight = () => getAppDimensions().height;
export const getAppWidth = () => getAppDimensions().width;

export const getCardHeight = (appHeight, appWidth) => {
  const width = getCardWidth(appHeight, appWidth);

  return width * CARD_SIDE_RATIO;
};

const getCameraWidth = () => getCardWidth();

const getMainColor = () => PRIMARY_COLOR;

const getMenuButtonSize = () => MENU_BUTTON_SIZE;

const getCardBorderColor = () => CARD_BORDER_COLOR;

const getCardHolderStyle = () => ({
  padding: 0.25 * getDefaultPadding(),
  backgroundColor: CARD_BORDER_COLOR,
});

const getCardTextBaseSize = () => 12;

const getContactsFontSize = () => 12;

export const getFieldFontSize = () => 18;

const capitalizeSingle = (str, lowerRest) => {
  const rest = str.slice(1);
  const restFin = lowerRest ? rest.toLowerCase() : rest;

  return `${str.charAt(0).toUpperCase()}${restFin}`;
};

const capitalize = (str, lowerRest) =>
  str
    .split(" ")
    .map(word => capitalizeSingle(word, lowerRest))
    .join(" ");

export {
  getAppDimensions,
  getHeaderHeight,
  getDefaultPadding,
  getTextLineHeight,
  getToolbarHeight,
  getCameraWidth,
  getMainColor,
  getMenuButtonSize,
  getCardBorderColor,
  getCardHolderStyle,
  getContactsFontSize,
  getCardTextBaseSize,
  capitalize,
};
