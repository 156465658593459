import { createAction } from "redux-actions";

import { getAccountId, getOrgId } from "../selectors/userAccount";
import { getNextId } from "../selectors/people";
import { initNewPerson } from "../actions/peopleEditor";
import { PEOPLE } from "../helpers/paths";

export const FETCH_PEOPLE = "PEOPLE/FETCH_PEOPLE";
export const FETCH_PEOPLE_SUCCESS = "PEOPLE/FETCH_PEOPLE_SUCCESS";
export const FETCH_PEOPLE_ERROR = "PEOPLE/FETCH_PEOPLE_ERROR";

export const ADD_PERSON = "PEOPLE/ADD_PERSON";
export const DELETE_PERSON = "PEOPLE/DELETE_PERSON";

const fetchPeopleSuccess = createAction(FETCH_PEOPLE_SUCCESS);
const fetchPeopleError = createAction(FETCH_PEOPLE_ERROR);
export const fetchPeople = createAction(
  FETCH_PEOPLE,
  null,
  () => ({
    method: "GET",
    view: "peopleList",
    path: "people",
    params: {
      userId: getAccountId,
      orgId: getOrgId,
    },
    needsAuthorization: true,
    onSuccess: fetchPeopleSuccess,
    onError: fetchPeopleError,
  }),
);

export const addPerson = createAction(
  ADD_PERSON,
  null,
  () => ({
    nextId: getNextId,
    initNew: initNewPerson,
    path: PEOPLE,
  }),
);

export const deletePerson = createAction(DELETE_PERSON);

