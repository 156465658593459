import React from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";
import { phonecall } from "react-native-communications";

import { getDefaultPadding, LINK_COLOR } from "../utils/styleUtils";

export const styles = StyleSheet.create({
  phoneContainer: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  phone: {
    color: LINK_COLOR,
  },
  textStyle: {
    marginRight: getDefaultPadding(),
  },
});

const call = phone => () => phonecall(phone.replace(/-| /g, ""), true);

const PhoneNumber = ({ type, phone, textStyle, style }) => (
  <TouchableOpacity onPress={call(phone)}>
    <View style={[styles.phoneContainer, style]}>
      {type && <Text style={[textStyle, styles.textStyle]}>{type}</Text>}
      <Text style={[styles.phone, textStyle]}>{phone}</Text>
    </View>
  </TouchableOpacity>
);
PhoneNumber.propTypes = {
  type: PropTypes.string,
  phone: PropTypes.string.isRequired,
  textStyle: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default PhoneNumber;
