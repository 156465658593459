import PropTypes from "prop-types";
import Immutable from "seamless-immutable";

import Card from "./card";

class CardList {
  constructor({
    cards,
  }) {
    return Immutable.from({
      cards: cards.map(card => {
        return new Card(card);
      }),
	}, { prototype: this.constructor.prototype });
  }
}

const cardListPropType = PropTypes.instanceOf(CardList);

export {
  CardList as default,
  cardListPropType,
};
