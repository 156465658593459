import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./PageContainer.css";

const PageContainer = ({ children, className }) => (
  <div className={classNames("page_container_main", className)}>
    {children}
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default PageContainer;
