import React from "react";

import "../css/Home.css";

const Home = () => (
  <div className="home_container">
    <h3>Home page is here</h3>
    <p>TODO: Recent cards</p>
    <p>TODO: ???</p>
  </div>
);

Home.displayName = "Home";

export default Home;
