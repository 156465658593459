import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDrag } from "react-dnd";

import { itemTypes } from "./dragDropConstants";

import "../../css/Pallette.css";

export const MenuItem = ({ name, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: itemTypes.NEW_COMPONENT },
    collect: monitor => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        // The component was dropped
        const { id } = monitor.getDropResult();

        onDrop(id);
      }
    }
  });

  return (
    <li ref={drag}>
      <div
        className={classNames(
          "pallette_item",
          { "pallette_item_dragging": isDragging }
        )}
      >
        {name}
      </div>
    </li>
  );
};

MenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
};
