import { useDrop } from "react-dnd";

export const itemTypes = {
  NEW_COMPONENT: 'new_component',
};

const containerTypes = ['container', 'paragraph'];

export function useNewComponentDrop(id, type) {
  return useDrop({
    accept: itemTypes.NEW_COMPONENT,
    canDrop: (item, mon) => !type || containerTypes.includes(type),
    drop: (e, mon) => {
      if (!mon.didDrop()) {
        return { id }
      }
    },
    collect: monitor => ({
      isOver: Boolean(
        monitor.isOver({shallow: true}) && monitor.canDrop()
      ),
    }),
  });
}
