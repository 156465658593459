import { takeEvery, put } from "redux-saga/effects";

import { SAVE_CARD, FETCH_CARD, DELETE_ITEM } from "../actions/cardEditor";
import { resetSettings } from "../actions/editorSettings";

function *resetEditor() {
  console.log("Reseting editor");
  yield put(resetSettings());
}

export default function *saveWatcher() {
  yield takeEvery([SAVE_CARD, FETCH_CARD, DELETE_ITEM], resetEditor);
}
